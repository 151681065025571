import React, { useEffect, useMemo, useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './reportBugDialog.scss';
import { LinearProgress } from '@material-ui/core';
type Props = {

}
export const DialogTypes = {
    OPEN: 'open',
    CLOSE: 'close'
}
export const useReportBug: () => { open: () => void; close: () => void; } = () => {
    const actionGetter = (type: string) => {
        return () => {
            // console.log('post message', type);
            window.postMessage({
                event_id: 'report-a-bug-dialog',
                data: type
            }, window.location.origin);
        }
    }

    return useMemo(() => {
        return {
            open: actionGetter(DialogTypes.OPEN),
            close: actionGetter(DialogTypes.CLOSE)
        }
    }, []);
}
const ReportBug: React.FC<Props> = () => {
    const [open, setOpen] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const iframeRef = useRef<any>();

    const handleClose = (e: any, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };


    useEffect(() => {
        let listener = (e: any) => {
            let { data } = e;
            if (data && data.event_id) {
                let { event_id, data: _data } = data as { event_id: string, data: string };
                event_id && console.log('event_id', event_id, _data);
                if (event_id === 'report-a-bug-dialog') {
                    // console.log('set open', event_id);
                    if (_data === DialogTypes.OPEN) {
                        setOpen(true);
                        setSuccessOpen(false);
                        setErrorOpen(false);
                    } else {
                        setOpen(false);
                    }
                } else if ('form_submitted_event' === event_id) {
                    switch (_data) {
                        case 'success':
                            setSuccessOpen(true);
                            break;
                        case 'error':
                            setErrorOpen(true);
                            break;
                    }
                }
            }
        }
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener)
        }
    }, []);

    const issueUrl = window.location.href;
    const iframeDataParams = useMemo(() => {
        return encodeURIComponent(JSON.stringify({
            4487433337107: issueUrl,
            // 29827987: "this is a test",
            // 29827997: "hello",
            // 4476143576595: "here we go",
            // 4476144919571: "learning_lab_central",
        }));
    }, [issueUrl]);

    const onIframeLoaded = () => {
        setIframeLoaded(true);
    }

    const attrs = {
        dividers: true,
        title: 'Talk to us',
        full: true
    }

    const renderMessag = function (type: 'success' | 'error') {
        let message;
        if (type === 'success') {
            message = 'Submitted successfully!';
        } else {
            message = 'Service is temporarily unavailable. Something wrong happened when communicating with the server. Please try again later.';
        }
        return (
            <div className={`result-message message-${type}`}>
                <i className='icon'></i>
                <div className='message-content'>{message}</div>
            </div>)
    }

    let content, mode = '';
    if (successOpen || errorOpen) {
        mode = 'result'
        attrs.dividers = false;
        attrs.title = '';
        attrs.full = false;
        content = renderMessag(successOpen ? 'success' : 'error');
    } else {
        content = (
            <React.Fragment>
                {iframeLoaded ? null : <LinearProgress />}
                <iframe ref={iframeRef} src={process.env.REACT_APP_FEEDBACK_URL + '?view=iframe&title=false&data=' + iframeDataParams} title='report bug' style={{ display: iframeLoaded ? 'block' : 'none' }} onLoad={onIframeLoaded}></iframe>
            </React.Fragment>)
    }


    return (
        <div className='report-bug-container'>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={attrs.full}
                // maxWidth='800px'
                className={`report-bug-dialog ${mode}`}
            >
                <DialogTitle id="alert-dialog-title">{attrs.title}
                    <IconButton aria-label="close" className="dialog-close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers={attrs.dividers} className="report-bug-dialog-content">
                    {content}
                </DialogContent>
            </Dialog>
        </div>)
}


export default ReportBug;