import React from 'react';
import './ModulCard.scss'

const ModulCard: React.FC<{
    data: any, progress?: any, labId?: string, recommendId?: string
}> = props => {
    let { data: module, progress: inProgress, labId = '', recommendId = '' } = props;
    // console.log("module recommendId:", recommendId)
    //@ts-ignore
    return <dwc-module-card percentage={inProgress ? JSON.stringify(inProgress.percentage) : '0'} progress={JSON.stringify(inProgress)} content={JSON.stringify(module)} labId={labId} recommendId={recommendId || ''}></dwc-module-card>
};

export default ModulCard;
