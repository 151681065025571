export {LAB,MODULE,TRACK} from "../../const"


export const STEP='step'
export const PROGRESS_IN_PROGRESS="InProgress"
export const PROGRESS_COMPLETED="Completed"
export const PROJECT_TYPE_TRACK='track'
export const PROJECT_TYPE_MODULE='module'
export const PROJECT_TYPE_LAB='learninglab'
export const PROJECT_TYPE_ALL='all'

export const tabList=[
    {key:PROGRESS_IN_PROGRESS,name:'In progress'},
    {key:PROGRESS_COMPLETED,name:'Completed'}
]

export const projectTypeList=[
    {name:'All',value:PROJECT_TYPE_ALL},
    {name:'Learning Track',value:PROJECT_TYPE_TRACK},
    {name:'Learning Module',value:PROJECT_TYPE_MODULE},
    {name:'Learning Lab',value:PROJECT_TYPE_LAB},
]

export const SORT_DESC='last_modified:desc'

export const DateSortList=[
    {name:'Newest to Oldest',value:SORT_DESC},
    {name:'Oldest to Newest',value:'last_modified:asc'},
]

export const CARD_TYPE_LIST='list';
export const CARD_TYPE_GRID='grid';

export const NO_CONTENT_IN_PROGRESS="You have completed all selected Learning Labs. Please choose a new topic to continue your learning journey!"
export const NO_CONTENT_COMPLETED="You haven't completed any courses. Please start or continue your learning journey!"
export const noContentWordsMap={
    [PROGRESS_IN_PROGRESS]:NO_CONTENT_IN_PROGRESS,
    [PROGRESS_COMPLETED]:NO_CONTENT_COMPLETED
}