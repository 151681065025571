import React from 'react';
import './CourseCard.scss'

import { object, bool, string } from "prop-types"


const CourseCard = props => {
    let { data: course, progress: inProgress, noBorder: isNoBorder, recommendId } = props;
    // console.log("course recommendId:", recommendId)
    return <dwc-track-card percentage={inProgress ? JSON.stringify(inProgress.percentage) : '0'} content={JSON.stringify(course)} progress={JSON.stringify(inProgress)} noBorder={JSON.stringify(isNoBorder)} recommendId={recommendId || ''}></dwc-track-card>

};

CourseCard.propTypes = {
    data: object,
    progress: object,
    noBorder: bool,
    recommendId: string
    //   title: PropTypes.string
};

export default CourseCard;
