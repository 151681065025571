import React, { useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useDevLearningWidget, useLearningWidgetStyles } from '../Search/hooks';

const Modules = () => {
  const classes = useLearningWidgetStyles();
  let { moduleCodeName } = useParams();
  // let urlId = labId ? `/${labId}` : ''
  // let [selectedKey, setSelectedKey] = useState(urlId)

  let pathname = useLocation().pathname;
  let [selectedKey] = useState(`/${pathname.split('/').slice(3).join('/')}/`.replace(/\/{1,}/g, '/'))
  const extraProps = {
    ratingformid: process.env.REACT_APP_RATING_FORM_ID
  }
  if (selectedKey) {
    extraProps.selecteditemkey = selectedKey;
  }

  useDevLearningWidget('dwc-module-ui', '/modules/' + moduleCodeName);

  return (


    <div
      className={classes.root}
    >


      <dwc-module-ui class={classes.dui} moduleid={moduleCodeName} community="true" status={process.env.REACT_APP_CONTENT_STATUS} {...extraProps}>
        <style>{`@import url('${process.env.REACT_APP_ASSET_URL_BASE}/form/styles/sdk.css')`}</style>
      </dwc-module-ui>
    </div>

  );
};

export default Modules;
