import React, { useEffect, useState, useCallback, useRef } from 'react';
import './searchInput.scss';
import SearchIcon from '@material-ui/icons/Search';
import SearchSuggest from '../../../components/SearchSuggest/SearchSuggest'
import {recommendedKeywords} from "../../../const"

type SearchInputProps = {
  onKeyChanged?: Function,
  onSearch: (key: string) => void,
  searchKey?: string,
  resultCount?: number,
  label?: string
}
const SearchInput: React.FC<SearchInputProps> = (props) => {
  let {
    onKeyChanged,
    onSearch,
    searchKey,
  } = props;
  
  const [key, setKey] = useState(searchKey || '');  
  const [focus, setFocus] = useState(false);
  const [ recommendedKeywordIndex,setRecommendedKeywordIndex]=useState(0);
  const intervalRef=useRef(0)

  const _onKeyChanged = useCallback(e => {
    let key = e.target.value;
    setKey(key);
    onKeyChanged && onKeyChanged(key);
    if(key){
      setFocus(true)
    }
  }, [onKeyChanged]);

  const _onSearch = useCallback(() => {
    setFocus(false)
    // const _key=key?key:!focus?`"${recommendedKeywords[recommendedKeywordIndex]}"`:""
    // onSearch && onSearch(_key);
    // setKey(_key)
    onSearch(key);
    setKey(key)
  }, [key, onSearch, recommendedKeywordIndex,focus]);

  const _onKeyDown = useCallback(e => {
    if (e.keyCode === 13) {
      _onSearch()
    }
  }, [_onSearch]);

  const onFocus = useCallback(e => {
    setFocus(true)
  }, []);

  const onBlur = useCallback(e => {
    setTimeout(() => {
      setFocus(false)
    }, 200)
  }, []);

  useEffect(() => {
    setKey(searchKey || '')
  }, [searchKey]);

  useEffect(()=>{
    if(!key&&!focus&&!intervalRef.current){
    intervalRef.current=window.setInterval(()=>{
      setRecommendedKeywordIndex(i=>(i+1)%recommendedKeywords.length)
    },4000)
    }
    
    return()=>{
      clearInterval(intervalRef.current);
      intervalRef.current=0
    }
  },[key,focus])


  function clearKey() {
    setKey('')
  }


  return (
    <div className='search-input'>
      <div className='topSearch'>
        <div className={`topSearchInput ${focus && 'inputFocused'}`}>
          <div className="iconSearch"></div>
          <input  type="text" value={key} onFocus={onFocus} onBlur={onBlur} onChange={_onKeyChanged}
            onKeyDown={_onKeyDown} />
           {!key&&!focus&&<div className='text-cycle'><i>I want to find</i> <span className="text-cycle_word">{`"${recommendedKeywords[recommendedKeywordIndex]}"`}</span></div>}
          {
            focus && key && key.length ?
              (<div className="iconClear" onClick={clearKey}></div>)
              : ''
          }
          <SearchSuggest searchWord={key} showSuggest={focus} selectSuggest={(key)=>onSearch(key)}></SearchSuggest>
        </div>
        <div className='topSearchBtn' onClick={_onSearch}>
          <span className='search-text'>Search</span>
          <SearchIcon />
        </div>
      </div>
    </div>
  )
}
export default SearchInput;