import React, {
  ReactNode, useEffect, useState, useRef,
} from 'react';

import './index.scss';

type Label = {
  text: ReactNode;
  value: string;
}

type Props = {
  labels: Label[];
  value: string;
  onChange?: (value: string) => void;
};

function Switch({
  labels,
  value,
  onChange = (): void => undefined,
}: Props): JSX.Element {
  const wrapper = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (wrapper.current) {
      const target = wrapper.current.querySelector(`[data-id="${value}"]`);
      if (target) {
        const { left: pLeft } = wrapper.current.getBoundingClientRect();
        const rect = target.getBoundingClientRect();

        setWidth(rect.width);
        setPosition(rect.left - pLeft - 1);
      }
    }
  }, [value]);

  return (
    <div ref={wrapper} className="switch">
      <div style={{ width, left: position }} className="switch__ball" />
      {labels.map((label) => (
        <div
          key={label.value}
          data-id={label.value}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && onChange(label.value)}
          onClick={() => onChange(label.value)}
          className={`switch__label${label.value === value ? ' switch__label--active' : ''}`}
        >
          {label.text}
        </div>
      ))}
    </div>
  );
};

export default Switch;
