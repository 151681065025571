import {useQuery} from "@tanstack/react-query"
import { api, request } from "../utils/request";
import useGetProgressByCodeNameList from "./useGetProgressByCodeNameList"
import {project} from "../types"
import {projectsQueryCommonParamsFilters} from "../const"

function getParamsByType(type:string){
    switch(type){
        case "latestLabs":
            return {pageSize:9,filters:[{"field":"contentSubtype","value":["lab"]}],sortBy:[{"field":"created_at","desc":true}]};
        case "latestModules":
            return {pageSize:9,filters:[{"field":"contentSubtype","value":["module"]}],sortBy:[{"field":"created_at","desc":true}]};
        case "latestTracks":
            return {pageSize:6,filters:[{"field":"contentSubtype","value":["track"]}],sortBy:[{"field":"created_at","desc":true}]};
        default:
            return {
                pageSize:100,filters:[
                    {"field":"contentSubtype","value":["track","module","lab"]},
                    {"field":"products","value":type.split(",")},
                ],
                sortBy:[{"field":"created_at","desc":true}]
            }
    }
}

export default function (type:string){
    const {data,isLoading}=useQuery(['getNewContent',type], async () => {
            const _params=getParamsByType(type);
            const params={
                "pageNum":1,
                "pageSize":_params.pageSize,
                "sortBy":[..._params.sortBy],
                "filters":[
                    ..._params.filters,
                    ...projectsQueryCommonParamsFilters
                ]
            }
            const response = await request.post(api.projectsQuery,params)
            return response.data.items.map((_:{source:project})=>_.source);
    },{ refetchOnWindowFocus: false });

    const progress= useGetProgressByCodeNameList(data||[])

    return {data,isLoading,progress}
}