import React, { ReactElement, useMemo } from "react"
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Checkbox, Fade } from '@material-ui/core';
import "./index.scss"
import { SORT_BY, SORT_BY_DEFAULT_KEY } from "../../const"
import { ContainerTypes } from "../../Tags/categories/interface"
import { useUrl, ContentSubTypes } from "../../hooks";

type Props = {
    contentType: string,
    children: ReactElement | null,
    sortBy?: string,
    isDevEnvOnly?: 'true' | 'false',
    onChange: ContainerTypes['onChange']
}
export default function (props: Props) {
    const { sortBy = SORT_BY_DEFAULT_KEY, onChange, contentType, isDevEnvOnly } = props;
    let { params } = useUrl();
    let {
        isDneOnly,
    } = params;
    // console.log("contentType, isDneOnly", contentType, isDneOnly)

    function handleSortByChange(e: any) {
        onChange({ sortBy: e.target.value })
    }

    function handleDevEnvFilter(e: any) {
        // console.log("handleDevEnvFilter", isDevEnvOnly)
        if (isDevEnvOnly === "true") {
            onChange({ isDevEnvOnly: undefined })
        } else {
            onChange({ isDevEnvOnly: "true" })
        }
    }

    function showDevEnvFilter() {
        if (contentType === 'track' && isDneOnly) {
            return false
        } else {
            return true
        }
        // return contentType === 'lab'
    }
    const tipText = useMemo(() => {
        let _text = ""
        switch (contentType) {
            case ContentSubTypes.ALL:
                _text = "Show All with  Interactive Labs Only";
                break;
            case ContentSubTypes.LAB:
                _text = "Show Interactive Labs Only";
                break;
            case ContentSubTypes.MODULE:
                _text = "Show Modules with  Interactive Labs Only";
                break;
            case ContentSubTypes.TRACK:
                _text = "Show Tracks with  Interactive Labs Only";
                break;
        }
        return _text
    }, [contentType])

    return <div className="search-filter-content">
        <div className="search-filter-content_left">
            <div className="search-filter-content_item">
                <span className="search-filter-content_item_title">Sort by:</span>
                <FormControl variant="outlined">
                    <Select
                        value={sortBy}
                        onChange={handleSortByChange}
                        MenuProps={{ TransitionComponent: Fade }}
                    >

                        {
                            Object.entries(SORT_BY).map((val,index) => {
                                return <MenuItem key={index} value={val[0]}>{val[1].label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            {showDevEnvFilter() && <div className="search-filter-content_item">
                <span onClick={handleDevEnvFilter} className="show-hands-on-lab_title search-filter-content_item_title">{tipText}</span>
                <Checkbox
                    checked={isDevEnvOnly === "true" ? true : false}
                    onClick={handleDevEnvFilter}
                    color="primary"
                    checkedIcon={<span className='icon checkedIcon' />}
                    icon={<span className='icon' />}
                    inputProps={{ 'aria-label': 'primary checkbox' }}

                />
            </div>}
        </div>
        <div className="search-filter-content_right">{props.children}</div>
    </div>
}