import React from 'react';
// import { makeStyles } from '@material-ui/styles';

import './BlankTip.css'

const BlankTip = (props) => {
    // const classes = useStyles(props);
   

    return (
        <div className="blankTips">
            <div className="blankTipsTitle">Sorry, we couldn’t find what you were looking for.</div>
            <div className="blankTipsDesc">Try broadening the filters or search for something else!</div>
        </div>
    )


}
export default BlankTip ;