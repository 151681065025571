import React from "react"
import './index.scss';

const navs = [
  {
    title: 'Documentation',
    description: 'Access API, SDK, data model documentation and more',
    link: 'Read',
    url: '/docs/',
    icon: 'doc',
  },
  {
    title: 'Sample Code ',
    description: 'Explore sample solutions and implementations in Code Exchange',
    link: 'Code',
    url: '/codeexchange/',
    icon: 'code',
  },
  {
    title: 'Sandbox',
    description: 'Try pre-built Cisco platforms to test APIs, SDKs, and solutions',
    link: 'Build',
    url: '/sandbox/',
    icon: 'sandbox',
  },
];

export default function Nav() {
  return (
    <section className="nav">
      <div className="nav__blur">
        <ul className="nav__items container">
          <li className="nav__item" style={{display:'flex'}}>
            <h2 className="nav__title nav__title--main" style={{marginBottom:18,textAlign:'center'}}>Check out other Cisco DevNet offerings</h2>
            {/* <button className="nav__button--hollow">Personalized quick start</button> */}
          </li>
          {navs.map((nav) => (
            <li key={nav.icon} className="nav__item">
              <div className={`nav__title nav__title--${nav.icon}`}>
                <i className={`nav__icon nav__icon--${nav.icon}`} />
                {nav.title}
              </div>
              <div className="nav__description">{nav.description}</div>
              <a href={nav.url} className={`btn btn-primary btn-small nav__button nav__button--${nav.icon}`}>
                {nav.link}
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M7.875 2.625L7.24937 3.23444L10.5656 6.5625H1.75V7.4375H10.5656L7.24937 10.7507L7.875 11.375L12.25 7L7.875 2.625Z" fill="currentColor" />
                </svg>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
