import {useQuery} from "@tanstack/react-query"
import { api, request } from "../utils/request";
import {project} from "../types"
import {projectsQueryCommonParamsFilters} from "../const"


export default function (){
    const {data,isLoading}=useQuery(['getStartNowContent'], async () => {
            const params={
                "pageNum":1,
                "pageSize":100,
                // "sortBy":[{"field":"importance","desc":true}],
                "filters":[
                    {"field":"contentSubtype","value":["track"]},
                    {"field":"additionalTags","value":"startnow"},
                    ...projectsQueryCommonParamsFilters
                ]
            }
            const response = await request.post(api.projectsQuery,params)
            return response.data.items.map((_:{source:project})=>_.source);
    },{ refetchOnWindowFocus: false });

    return {data,isLoading}
}