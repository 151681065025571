import React from 'react';
import './LabCard.scss'

const 
LabCard = props => {
    let { data: lab, progress,recommendId = '' } = props;
    // console.log("LabCard:",lab,progress)
    // console.log("lab recommendId:", recommendId)
    return <dwc-lab-card percentage={progress?JSON.stringify(progress.percentage):'0'} progress={JSON.stringify(progress)} content={JSON.stringify(lab)} recommendId={recommendId || ''}></dwc-lab-card>
    
};

export default LabCard;
