import React, { useCallback, useEffect, useState } from "react";
import './ScrollToTop.scss';

export const toTop = function (behavior?:ScrollBehavior) {
    window.scroll({
        left: 0,
        top: 0,
        behavior: behavior||"smooth"
    });
}
const getScrollTop = () => document.documentElement.scrollTop;
const breakPoint = 800;
export default function () {
    const [show, setShow] = useState(getScrollTop() > breakPoint);
    const scrollFn = useCallback(() => {
        setShow(getScrollTop() > breakPoint);
    }, []);
    useEffect(() => {
        window.addEventListener('scroll', scrollFn);
        return () => window.removeEventListener('scroll', scrollFn);
    }, [scrollFn]);
    return (
        <div className={"scroll-to-top" + (show ? ' show' : '')} onClick={()=>toTop()}>
            <i className="far fa-arrow-to-top"></i>
        </div>
    )
}