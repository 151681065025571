import React, { useEffect, useState,useMemo } from 'react';
import { api } from '../../utils/request';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import './SearchSuggest.scss';
import { useUrl } from "../../pages/Search/hooks";
type SuggestProps = {
      searchWord: string,
      showSuggest?: boolean,
      selectSuggest?: (searchWord: string) => void,
}
const SearchSuggest: React.FC<SuggestProps> = (props: SuggestProps) => {
      let { searchWord, showSuggest, selectSuggest } = props
      type Suggest = {
            name: string,
            highlight: string,
            score: number
      }
      const [suggestions, setSuggestions] = useState<[Suggest] | []>([]);
      const [selectIndex, setSelectIndex] = useState(-1);
      let { params } = useUrl();
      let { contentType = '' } = params;
      async function fetchData(key: string) {
            try {
                  //@ts-ignore
                  let res = await axios.post(api.suggest2, {
                         key: key,
                         filters: [
                              {
                                  field: "contentSubtype",
                                  value: contentType.split(',')
                              }
                          ],
                  })
                  //@ts-ignore
                  let suggestArr = res.data
                  // console.log("suggestArr", suggestArr)
                  setSuggestions(suggestArr)
            } catch (err) {
                  console.log(err)
            }
      }
      useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
                  // console.log(searchWord)
                  if(searchWord){
                        fetchData(searchWord)
                  }
            }, 300)

            return () => clearTimeout(delayDebounceFn)
      }, [searchWord]);
      function clickSuggest(name: string) {
            // console.log("clickSuggest", name)
            selectSuggest?.(`\"${name}\"`)
      }

      function initDown(event: any) {
            // input.addEventerListener
            event = event || window.event;

            if (event.keyCode === 40) {
                  let nextSelectIndex = selectIndex + 1;
                  // console.log("0", event.keyCode, nextSelectIndex, suggestions.length)
                  if (nextSelectIndex + 1 > suggestions.length) {
                        nextSelectIndex = suggestions.length - 1
                  }
                  setSelectIndex(nextSelectIndex)

                  // console.log("1", event.keyCode, nextSelectIndex)
            };
            if (event.keyCode === 38) {
                  let nextSelectIndex = selectIndex - 1;
                  // console.log(event.keyCode, 0, nextSelectIndex)
                  if (nextSelectIndex < 0) {
                        nextSelectIndex = 0
                  }
                  setSelectIndex(nextSelectIndex)
                  // console.log(event.keyCode, nextSelectIndex)

            }
            if (event.keyCode === 13) {
                  // console.log("13",event.keyCode,selectIndex)
                  if (selectIndex < 0) return
                  let name = suggestions[selectIndex]?.name
                  // console.log("13",event.keyCode, name)

                  selectSuggest?.(`\"${name}\"`)

            }
      }
      useEffect(() => {

            window.addEventListener("keyup", initDown)
            return () => {
                  window.removeEventListener("keyup", initDown)

            }
      }, [searchWord, selectIndex, suggestions.length])
      useEffect(() => {
            setSelectIndex(-1)
      }, [searchWord])


      return (<React.Fragment>
            {showSuggest && suggestions?.length ?
                  <div className="searchSuggest">
                        < div className="searchSuggestTitle" > Search Suggestions</div >
                        {
                              suggestions.map((suggest: Suggest, index: number) => {
                                    let newHtml = suggest?.highlight
                                    // let newHtml = suggest?.highlight.replace(new RegExp('<mark>', 'g'), '<div class="suggestItem">')
                                    // newHtml = newHtml.replace(new RegExp('</mark>', 'g'), '</div>')
                                    //@ts-ignore
                                    return <button className={`suggestWarp ${selectIndex === index ? 'selectedSuggest' : ''}`} key={index} onClick={() => clickSuggest(suggest?.name)}>
                                          <div className="iconSearch"></div>
                                          <div className="suggestText">
                                                {ReactHtmlParser(newHtml)}
                                          </div>
                                    </button>
                              })
                        }
                  </div > : ''}
      </React.Fragment>
      )
}
export default SearchSuggest