import React, {
    useCallback,
    useEffect,
    useState,
} from "react";
import SearchBanner from "./components/Banner"
import SearchTab from "../../components/Tab"
import NewMenu from "../../components/NewMenu"
import { useHistory,useLocation} from "react-router-dom";
import ForYou from "./components/ForYou"
import Search from "./Search"
import { useUrl } from "./hooks";
import { SORT_BY_NAMES,tabList,TAB_FOR_YOU,TAB_LIBRARY } from "./const"
import {canGoToForYou,isLoginedIn} from "../../utils"

function protectForYou(key:string){
  if(key===TAB_FOR_YOU){
    if(!canGoToForYou()){
      return TAB_LIBRARY
    }
  }
  return key
}

function isForYouPage(){
  const pathname=window.location.pathname;
  const paths=pathname.split("/").filter(_=>_&&_!=="learning");
  if(paths.length===0||paths[1]===TAB_FOR_YOU){
    return true
  }
  return false
}

export default function (){

    let { params, updateUrl } = useUrl();
    const {push}=useHistory();
    const {pathname}=useLocation()
    const {sortBy,keyword: searchKey} = params;

    function getTabKeyByUrl(){
        if(isForYouPage()){
          return protectForYou(TAB_FOR_YOU)
        }else{
          return TAB_LIBRARY
        }
    }

    const [tabKey,setTabKey]=useState(getTabKeyByUrl())
    const isForYou=tabKey===TAB_FOR_YOU;
    
    useEffect(()=>{
      setTabKey(getTabKeyByUrl())
    },[pathname])

    const onTabChange=useCallback((key:string)=>{
        let _key=protectForYou(key)
        if(_key===TAB_FOR_YOU){
          if(!isForYouPage()){
            push("/"+window.location.search)
          }
        }
        if(_key===TAB_LIBRARY){
          if(isForYouPage()){
            push("/search/"+window.location.search)
          }
        }
        setTabKey(_key)
    },[])

    const onSearch = useCallback(
        (key) => {
          updateUrl({ keyword: key, sortBy: key ? SORT_BY_NAMES.luceneScore : sortBy });
        },
        [updateUrl]
    );
    


    return <div>
        {!isLoginedIn()&&<NewMenu label="" hiddenLibrary={true} inHomepage={false}></NewMenu>}
        <SearchBanner searchKey={searchKey} onSearch={onSearch}></SearchBanner>
        <div>
            <div className="container search_tab"><SearchTab list={tabList} onChange={onTabChange} selectKey={tabKey}></SearchTab></div>
            {isForYou&&<ForYou></ForYou>}
            {!isForYou&&<Search></Search>}
        </div>
    </div>
}