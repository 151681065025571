import React, { useEffect,useState } from 'react'
import NPS from "../NPS"
import useFetchNPS from "./hooks/useFetchNps"
import useTriggerNps,{getNpsData} from "./hooks/useTriggerNps"

const LAB_COMPLETE_LISTENER='scrolltobottom'

export default function LearningCenterNPS(){
    const [completeTime, setCompleteTime] = useState(0)
    const {nps}=useFetchNPS([completeTime])
    useTriggerNps(nps)
    //@ts-ignore
    function listenCompleteLab(e) {
        if (e.detail) {
            if (e.detail.percentage == 100) {
                setCompleteTime(completeTime => completeTime + 1)
            }
        }
    }
    useEffect(() => {
        window.addEventListener(LAB_COMPLETE_LISTENER, listenCompleteLab);
        return () => {
            window.removeEventListener(LAB_COMPLETE_LISTENER, listenCompleteLab)
        }
    }, [])


    return <NPS id={process.env.REACT_APP_NPS_ID!} env={process.env.REACT_APP_ENV} getFormMetadata={getNpsData}></NPS>
}