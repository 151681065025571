import React,{useEffect} from "react"
import "./index.scss"
import { useHistory } from "react-router-dom"
import mp4Url from "../../../../assets/video/LearningLabs.mp4"
import webmUrl from "../../../../assets/video/LearningLabs.webm"

const navs=[
    {title:'Learning Labs Center',url:'/'},
    {title:'My Learning',url:'/mylearning'}
]

export default function Banner(){
    const {push}=useHistory();
    // useEffect(() => {
    //     const bgvid: HTMLVideoElement | null = document.querySelector("#bg-video");
    //     if (bgvid) {
    //         bgvid.playbackRate = 0.5
    //     }
    // })
    
    return<div className="mylearning_banner_wrap">
                <video muted autoPlay loop playsInline className="banner_video" id="bg-video">
                    <source src={webmUrl} type="video/webm"/>
                    <source src={mp4Url} type="video/mp4"/>
                </video>
                <header className="mylearning_banner container">
                    <ul className="learning_navs">
                        {navs.map((_,index)=>{
                            const canClick=index<navs.length-1;
                            return <li className={`learning_navs_item`} onClick={canClick?()=>push(_.url):()=>{}}>{_.title}{canClick?<span>&gt;</span>:''}</li>
                        })}
                    </ul>
                    <p className="mylearning_banner_title">My Learning</p>
                </header>
            </div>
}