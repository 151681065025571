import React from "react"
import useGetPopularContent from "../../requestHooks/useGetPopularContent"
import Spinner from '../../components/Spinner';
import Swiper from "../../components/Swiper";
import { project } from "../../types"
import Card from "../../components/Card"

export default function PopularContent() {
  const { data, isLoading, progress } = useGetPopularContent()

  return <div style={{ minHeight: 300 }} className={`${isLoading ? 'list-loading' : ''}`}>
    {isLoading && <Spinner size="big" />}
    {data?.popular && <Swiper slidesToShow={1} breakpoint={{ 821: { slidesToShow: 4 } }} recommendId={data?.recommend_id || ''}>
      {data?.popular.map((item: project) => {
        const p = (progress[item.contentSubtype] || {})[item.code_name]
        return <Card progress={p} key={item.id} data={item} recommendId={data?.recommend_id || ''}></Card>
      })}
    </Swiper>}
  </div>

}