import { useQuery } from "@tanstack/react-query"
import { api, request } from "../utils/request";
import { getProjectsByCodeNameList } from "./index"
import useGetProgressByCodeNameList from "./useGetProgressByCodeNameList"


export default function () {
  const { data, isLoading } = useQuery(['userInterests'], async function fetchPopular() {
    const res = await request.post(api.userInterests, {
      resource_type: ['lab_track', 'lab_module', 'lab_lab'],
      resource_number: [3, 3, 4],
    })
    const interests = await getProjectsByCodeNameList(res.data.user_interests)
    interests.sort(() => .5 - Math.random())
    const recommend_id = res.data.recommend_id
    return { interests, recommend_id }

  }, { refetchOnWindowFocus: false });

  const progress = useGetProgressByCodeNameList(data?.interests || [])
  return { data, isLoading, progress }
}