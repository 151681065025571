import React from 'react'
import './index.scss';

type Props = {
  direction?: 'down' | 'right' | 'left' | 'up';
  title?: string;
  className?: string;
  disabled?: boolean;
  color?:string;
  size?:string
}

function Chevron({
  direction = 'down',
  title = undefined,
  className = '',
  disabled = false,
  color="currentColor",
  size="16"
}: Props): JSX.Element {
  return (
    <i
      title={title}
      className={[`chevron chevron--${direction}`, disabled && 'chevron--disabled', className].filter((i) => i).join(' ')}
    >
      <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        {direction === 'down' && <path d="M8 10.9998L3 5.9998L3.7 5.2998L8 9.5998L12.3 5.2998L13 5.9998L8 10.9998Z" fill={color} />}
        {direction === 'right' && <path d="M10.9998 8L5.9998 13L5.2998 12.3L9.5998 8L5.2998 3.7L5.9998 3L10.9998 8Z" fill={color} />}
        {direction === 'left' && <path d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z" fill={color} />}
        {direction === 'up' && <path d="M8 5L13 10L12.3 10.7L8 6.4L3.7 10.7L3 10L8 5Z" fill={color} />}
      </svg>
    </i>
  );
}

export default Chevron;
