import React, { useCallback, useEffect, useMemo, useState} from "react";
import "./MyLearning.scss";
import { isLoggedIn } from "../../typings/shared";
import Banner from "./components/Banner"
import Switch from "./components/Switch"
import Tab from "../../components/Tab"
import Spinner from '../../components/Spinner';
import {toTop } from "../../components/ScrollToTop/ScrollToTop"
import Icon from "../../components/Icon"
import ListCard from "./components/ListCard";
import Card from "../../components/Card";
import Select from "./components/Select"
import {NoLogin,NoContent} from "./components/Blank"
import { Pagination} from "@material-ui/lab";
import {useGetUserProgress} from "./requestHooks"
import { TitleAndDescribtion, usePageMetadata } from "../../utils";
import {useWindowWidth} from "../../utils/windowSize"
import {tabList,projectTypeList,SORT_DESC,DateSortList,CARD_TYPE_LIST,CARD_TYPE_GRID,PROGRESS_IN_PROGRESS,PROGRESS_COMPLETED} from "./const"
import {PROJECT_TYPE_LAB,PROJECT_TYPE_MODULE,PROJECT_TYPE_TRACK,PROJECT_TYPE_ALL} from "./const"
import { project as Project } from "../../types";


const typeWords=['track','module','lab']

type TabValue=typeof PROGRESS_IN_PROGRESS| typeof PROGRESS_COMPLETED

function getTypeWords(nums:number[]){
  return nums.reduce((prev,cur,i)=>{
    if(cur>0){
      return prev+`${prev.length>0?`, `:''}`+`<span>${cur}</span> ${typeWords[i]}${cur>1?'s':''}`
    }
    return prev
  },'')
}

function getCountInfo(status:string,type:string){
  if(!type) return ''
  if(status===PROGRESS_IN_PROGRESS){
    return `You have ${type} in progress. Keep up the great work!`
  }

  if(status===PROGRESS_COMPLETED){
    return `You've successfully completed ${type}. Well done!`
  }
  return ''
}

const labels=[
  {text:<div className="list__view"><Icon type="list" />List</div>,value:CARD_TYPE_LIST},
  {text:<div className="list__view"><Icon type="grid" />Grid</div>,value:CARD_TYPE_GRID}
]

export const Mylearning = () => {
  const [page,setPage]=useState(1);
  const [projectType,setProjectType]=useState(PROJECT_TYPE_ALL);
  const [dateSort,setDateOrder]=useState(SORT_DESC);
  const [tabType, setTabType] = useState<TabValue>(PROGRESS_IN_PROGRESS);
  const [cardType,setCardType]=useState(CARD_TYPE_LIST)

  const windowSize=useWindowWidth()

  useEffect(()=>{
    if(windowSize<=1024){
      setCardType(CARD_TYPE_GRID)
    }
  },[windowSize])

  const {total_pages,isLoading,progress,projects,total,track_total,module_total,lab_total}=useGetUserProgress({page,type:projectType,sort:dateSort,status:tabType})
  usePageMetadata(TitleAndDescribtion.mylearning.htmlTitle, TitleAndDescribtion.mylearning.metaDescription);

  const changeTab = (value: TabValue) => {
    setPage(1);
    setTabType(value);
  };


  const tabSelectNums=useMemo(()=>{
    let num=0;
    if(projectType!==PROJECT_TYPE_ALL){
      num=num+1
    }
    if(dateSort!==SORT_DESC){
      num=num+1
    }
    return num
  },[projectType,dateSort])

  const _tabList=useMemo(()=>{
    return tabList.map(_=>{
      return {..._,name:`${_.name} ${(_.key===tabType&&total)?`(${total})`:''}`}
    })
  },[tabType,total])

  const countInfo=useMemo(()=>{
    let nums:number[]=[track_total,module_total,lab_total]
    if(projectType!==PROJECT_TYPE_ALL){
      nums=[
        projectType===PROJECT_TYPE_TRACK?total:0,
        projectType===PROJECT_TYPE_MODULE?total:0,
        projectType===PROJECT_TYPE_LAB?total:0
      ]
    }
    return getCountInfo(tabType,getTypeWords(nums))
  },[total,track_total,module_total,lab_total,tabType,projectType])

  const handleTypeChange=useCallback(function handleTypeChange(value:string){
    setPage(1);
    setProjectType(value)
  },[])

  const onPageNumChange=useCallback(function onPageNumChange(event: React.ChangeEvent<unknown>, page: number){
    toTop("auto")
    setPage(page)
  },[])

  const isBlank=projects.length===0&&!isLoading;

  if(!isLoggedIn()){
    return <div className="columnFlex">
      <Banner></Banner>
      <div className="container columnFlex" style={{justifyContent:'center'}}>
        <NoLogin></NoLogin>
      </div>
    </div>
  }

  return (
    <div className="columnFlex">
      <Banner></Banner>
      <div className="container columnFlex">
        <div className="mylearning-navs">
          <Tab tabSelectNums={tabSelectNums} onChange={(key)=>changeTab(key)} selectKey={tabType} list={_tabList}>
            <div className="mylearning-navs_right">
              <Select list={projectTypeList} value={projectType} onChange={handleTypeChange}></Select>
              <Select title="Date" list={DateSortList} value={dateSort} onChange={setDateOrder}></Select>
              {windowSize>1024&&<Switch labels={labels} value={cardType} onChange={setCardType}></Switch>}
            </div>
          </Tab>
        </div>
        {isLoading?<div className="list-loading_wrap" style={{minHeight:400,display:'flex'}}><Spinner size="big" /></div>:null}
        <p className="mylearning_count-info" dangerouslySetInnerHTML={{__html:countInfo}}/>
        <div className="mylearning_content">
          {!isLoading&&projects.map((item:Project)=>{
            const p=(progress[item.contentSubtype.replace("learning",'')]||{})[item.code_name]
            
            return <div className={`mylearning_content_item mylearning_content_item_${cardType}`} key={item.code_name}>
             {cardType===CARD_TYPE_LIST?<ListCard key={item.code_name} data={item} progress={p||{}}></ListCard>:
              <Card key={item.code_name} data={item} progress={p||{}}></Card>
             }
            </div>
          })}
          {isBlank&&<NoContent projectType={tabType}></NoContent>}
        </div>
          {total_pages > 1&&<div className="mylearning_pagination">
              <Pagination
                count={total_pages}
                page={Number(page) || 1}
                onChange={onPageNumChange}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
          </div>}
      </div>
    </div>
  );
};

export default Mylearning;


