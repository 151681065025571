import {useQuery} from "@tanstack/react-query"
import { api, request } from "../utils/request";
import {progress as progressType,project,CodeNameListItem} from "../types"
import {LAB,MODULE,TRACK,SORT_DESC} from "../const"
import { useState } from "react";
import {projectsQueryCommonParamsFilters} from "../const"

type Props={
    page?:number,
    type?:string,
    sort?:string,
    status?:string,
    pageSize?:number,
    enabled?:boolean
}


export function useGetUserProgress(props:Props){
    const {page=1,type,sort=SORT_DESC,status,pageSize=12,enabled=true}=props;
    const {data={progress:{} as {[key:string]:{[name:string]:progressType}},projects:[],total_pages:0},isLoading,isFetching}=useQuery(['userProgress',page,type,sort,status], async function fetchUserProgress() {
        const res = await request.get(`${api.userProgress}&page=${page}&size=${pageSize}&status=${status}&sort=${sort}${type&&type!=="all"?`&type=${type}`:""}`);
        const {data}=res;
        const {data:progress,total_pages,...other}=data;
        
        const progressMap:{[key:string]:{[name:string]:progressType}}={}
        progress.forEach((item:progressType)=>{
            const {type,code_name}=item;
            let _type=type==="learninglab"?'lab':type;
            progressMap[_type]=progressMap[_type]||{};
            progressMap[_type][code_name]=item
            
        })
  
        const query=await getProjectsByCodeNameList(progress)
        
        return {progress:progressMap,projects:query,total_pages,...other}
    } ,{refetchOnWindowFocus: false,enabled});


    return {...data,isLoading,isFetching}
}

type detailItem={
    code_name:string,
    id:string,
    items?:detailItem[],
    progress:{
        percentage:number
    }
}

export function useGetProgressDetail(props:{type:string,code_name:string,show:boolean}){
    const {type,code_name,show}=props;
    
    const [detail,setDetail]=useState<{[code_name:string]:{[id:string]:detailItem}}>({})
    function getUrl(){
        switch(type){
            case TRACK:
                return api.coursesProgress;
            case MODULE:
                return api.modulesProgress;
            default:
                return api.labsProgress;
        }
    }
    
    const {data,isLoading}=useQuery(["progressDetail",type,code_name,show],async()=>{
        const url=getUrl();
        const res=await request.get(url+'/'+code_name);
        const {data}=res;
        const {activities}=data;
        const children=type===TRACK?data.modules:data.submodules
        
        const map=children.reduce((obj:{[id:string]:detailItem},item:detailItem)=>{
            if(item.items){
                item.items.forEach(_=>{
                    obj[_.id]=_
                })
            }else{
                obj[item.code_name]=item
            }
            return obj
        },{})
        setDetail((d)=>({...d,[code_name]:map}))
        return {data:map,activities}
    },{enabled:show&&type!==LAB})

    return {detail:data,isLoading,detailMap:detail}
}


export async function getProjectsByCodeNameList(list:CodeNameListItem[]){
    const nameList=list.map(_=>_.code_name)
    let res = await request.post(api.projectsQuery, {
        filters: [
              {
                    field: "code_name",
                    value: nameList,
              },
              ...projectsQueryCommonParamsFilters
        ],
        pageSize: 1000
    })

    const items=res?.data?.items||[];
    const map:{[key:string]:{[key:string]:project}}={};
    items.forEach((item:{source:project})=>{
        const source=item.source;
        let type=source.contentSubtype;
        map[type]=map[type]||{}
        map[type][source.code_name]=source;
    })


    const data = list.map(item=>{
        let type=item.type.replace("learninglab_","");
        type=type==="learninglab"?'lab':type;
        return ((map[type])||{})[item.code_name]
    }).filter(_=>_)
    
    return data
}