export function isLoginedIn(){
    //@ts-ignore
    if(!window.PlatformSDK||!window.PlatformSDK.isLoggedIn()) return false
    return true
}

export function emptyObj<T>(val:null|T){
    if(typeof val==="object"&&val!==null){
        if(Object.keys(val).length===0){
            return true
        }
    }

    return false
}