import React, { useCallback } from "react"
import "./index.scss"
import {ContainerTypes,specificContainerProps} from "./interface"
import {Category,Categories,ClearBar,Products,MobileBar,Language} from "./components"
export {MobileBar} from "./components"


function ContainerStandalone(props:specificContainerProps){
    const {selected,selectedProducts,onChange,languageSelected}=props;

    const clear=useCallback(()=>{
        onChange({products:'',language:''})
    },[onChange])

    function hiddenClearAll(){
        return selectedProducts.length==0
    }

    if(selected.length==0){
        return <div className="category-box" style={{borderRadius:4}}>
                    <Categories onChange={onChange} selected={selected}></Categories>
               </div>
    }else{
        return <div>
            <div style={{marginBottom:20}} className="category-wrap">
                <Category onClick={onChange} data={selected[0]}></Category>
            </div>
            <ClearBar hiddenBtn={hiddenClearAll()} onClear={clear}></ClearBar>
            <div>
                <div className="category-box category-box_products" style={{padding:10}}>
                    <Products selected={selectedProducts} onChange={onChange}></Products>
                </div>
                <div className="category-box category-box_language" style={{padding:10}}>
                    <Language selected={languageSelected} onChange={onChange}></Language>
                </div>
            </div>
        </div>
    }
}

function ContainerCombine(props:specificContainerProps){
    const {selected,selectedProducts,onChange,languageSelected}=props;

    const clear=useCallback(()=>{
        onChange({categories:'',products:''})
    },[onChange])

    const clearProducts=useCallback(()=>{
        onChange({products:''})
    },[onChange])
    
    function hiddenClearAll(){
        return selected.length==0&&selectedProducts.length==0
    }

    return <div>
                {selected.length>0&&<div className="category-wrap" style={{marginBottom:20}}>
                    <Category onClick={onChange} data={selected[0]}></Category>
                </div>}
                <ClearBar hiddenBtn={hiddenClearAll()} onClear={selected.length>0?clearProducts:clear}></ClearBar>
                <div className="category-box">
                    {selected.length==0&&<div className="category-box_bottom"><Categories onChange={onChange} selected={selected}></Categories></div>}
                    <div style={{padding:10}}>
                        <Products selected={selectedProducts} onChange={onChange}></Products>
                    </div>
                    <div style={{padding:10}}>
                        {/* <Language selected={languageSelected} onChange={onChange}></Language> */}
                    </div>
                </div>
            </div>
}




export default function(props:ContainerTypes){
    const {mode,selected,selectedProducts,languageSelected,...otherProps}=props;
    const _selected=selected.filter(_=>_)
    const _selectedProducts=selectedProducts.filter(_=>_);
    const _languageSelected=languageSelected.filter(_=>_)
    //  style={{maxHeight:'100vh',overflow:'auto'}}
    return <div>
        {mode=="combine"&&<ContainerCombine {...otherProps} selected={_selected} selectedProducts={_selectedProducts} languageSelected={_languageSelected}></ContainerCombine>}
        {mode!="combine"&&<ContainerStandalone {...otherProps} selected={_selected} selectedProducts={_selectedProducts} languageSelected={_languageSelected}></ContainerStandalone>}
    </div>
}