import { useLocation, useHistory } from 'react-router-dom';
import { useMemo, useState, useEffect, useCallback, ElementType } from 'react';
import queryString from "query-string";
import { request, api } from '../../utils/request';
import { LocationState, LocationDescriptor } from 'history';
import { CancelToken } from 'axios';
import useSWR from 'swr';
import { makeStyles } from '@material-ui/core';
import { isLoggedIn } from '../../typings/shared';
import { SEARCH_KEYS_MAP, SORT_BY_DEFAULT_KEY, LANGUAGE_MAP, ERROR_PAGE_MAP } from "./const"
import {projectsQueryCommonParamsFilters} from "../../const"
import { searchPageScrollToTop } from "./utils"
import { SearchUrlType } from "./interface"
import { setCanonicalLink } from '../../utils/index'

export const SearchTypes = {
    ALL: 'all',
    LAB: 'lab',
    MODULE: 'module',
    TRACK: 'track',
    DNE: 'dne'
}
export const ContentSubTypes = {
    ALL: 'track,module,lab',
    LAB: 'lab',
    MODULE: 'module',
    TRACK: 'track'
}
export type SearchType = {
    id: string;
    label: string;
    contentSubtype: string;
    isDneOnly?: "true" | 'false';
    isDevEnvOnly?: "true" | 'false';
    total?: number
}
export const searchTypes: { [x: string]: SearchType } = {
    [SearchTypes.ALL]: {
        id: SearchTypes.ALL,
        label: 'All',
        contentSubtype: ContentSubTypes.ALL
    },
    [SearchTypes.LAB]: {
        id: SearchTypes.LAB,
        label: 'Learning Labs',
        contentSubtype: ContentSubTypes.LAB
    },
    [SearchTypes.MODULE]: {
        id: SearchTypes.MODULE,
        label: 'Learning Modules',
        contentSubtype: ContentSubTypes.MODULE
    },
    [SearchTypes.TRACK]: {
        id: SearchTypes.TRACK,
        label: 'Learning Tracks',
        contentSubtype: ContentSubTypes.TRACK
    },
    [SearchTypes.DNE]: {
        id: SearchTypes.DNE,
        label: 'Workshops',
        contentSubtype: ContentSubTypes.TRACK,
        isDneOnly: 'true'
    },
}

function getParamsFromPath(path: string) {
    path = path.replace(/^(\/)|(\/)$/g, "");
    let pathParts = path.split("/");
    pathParts=pathParts.filter(path=>path&&path!=="forYou");
    if(pathParts.length===0){pathParts.push("search")}
    
    let start = 1;
    let contentType = '';
    let pageType = pathParts[0]
    const params = new Map();
    if (pathParts[0] == "search") {
        if (pathParts.length > 1) {
            for (let val of Object.values(ContentSubTypes)) {
                if (val + "s" == pathParts[1]) {
                    contentType = val;
                    pageType = val;
                    start = 2;
                    break;
                }
            }
            for (let i = start; i < pathParts.length; i += 2) {
                let key = pathParts[i];
                key = SEARCH_KEYS_MAP.get(key) || key;
                params.set(key, pathParts[i + 1])
            }
        }
    } else {
        contentType = pageType.slice(0, -1)
    }
    return { path: pathParts.slice(0, start).join("/"), params, contentType, pageType }

}

function isDevEnvOnlyUsable(contentType: string, isDneOnly?: string | undefined) {
    if (contentType === 'track' && isDneOnly) {
        return false
    } else {
        return true
    }
}

export function useUrl() {
    const loc = useLocation();
    const history = useHistory();
    let { pathname, search } = loc;
    // console.log("useUrl pathname, search,loc:",pathname, search,loc)

    const paramsFromUrl = useMemo(() => {
        const { params, contentType: _contentType, pageType } = getParamsFromPath(pathname);
        let {
            contentType,
            complexity,
            industries,
            keyword,
            products,
            language,
            isDneOnly,
            categories,
            page,
            sortBy,
            isDevEnvOnly
        } = queryString.parse(search) as SearchUrlType;
        const urlObjContentType = _contentType ? _contentType : contentType ? contentType : ContentSubTypes.ALL
        let urlObj: SearchUrlType = {
            contentType: urlObjContentType,
            complexity,
            industries,
            keyword,
            products,
            language,
            categories,
            isDneOnly,
            page,
            pageType,
            sortBy,
            isDevEnvOnly: isDevEnvOnlyUsable(urlObjContentType) ? isDevEnvOnly : undefined
        }
        params.forEach((val, key) => {
            //@ts-ignore
            urlObj[key] = val
        })
        return urlObj;
    }, [search, pathname]);


    const updateUrl = useCallback((urlData: Partial<SearchUrlType>) => {
        let urlObj = Object.assign(paramsFromUrl, urlData);
        const { params, path } = getParamsFromPath(pathname);
        
        let _pathname = path
        
        Object.entries(urlObj).forEach((val) => {
            if (SEARCH_KEYS_MAP.has(val[0])) {
                if (val[1] === undefined || val[1] === "") {
                    params.delete(val[0])
                } else {
                    params.set(val[0], val[1])
                }
            }
        })

        // params.forEach((val, key) => {
        //     _pathname = `${_pathname}/${SEARCH_KEYS_MAP.get(key)}/${val}`
        // })

        let obj = {};

        if (!isDevEnvOnlyUsable(urlObj['contentType'], urlObj['isDneOnly'])) {
            urlObj['isDevEnvOnly'] = undefined
        }
        for (let key in urlObj) {
            //@ts-ignore
            // if (!(key === undefined || urlObj[key] === '') && !SEARCH_KEYS_MAP.has(key))
            if (!(key === undefined || urlObj[key] === '')) {
                if ((path !== "search" && key === "contentType") || (key === "pageType")) {
                    continue
                }
                //@ts-ignore
                obj[key] = urlObj[key]?.replace(/(&|\?)/g, (_, found) => {
                    return encodeURIComponent(found);
                })
            }
        }

        //@ts-ignore
        obj.page = 1

        let state: LocationDescriptor<LocationState> = {
            pathname: "/" + _pathname + "/",
            search: queryString.stringify(obj, { encode: false })
        };

        searchPageScrollToTop()
        history.push(state)
    }, [history, paramsFromUrl, pathname]);


    const rtn = useMemo(() => {
        return {
            params: paramsFromUrl,
            updateUrl,
            paths: pathname.substr(1).split('/')
        }
    }, [paramsFromUrl, updateUrl, pathname]);

    return rtn;
}

export type QueryResult = {
    pageCount: number,
    total: number,
    dataList: any[]
}
type hightLight = {
    name: string,
    description: string,
    contentFullText: string,
}

const pageSize = 21;
export function doQuery(params: SearchUrlType, aggregations?: Record<'field', string>[], cancelToken?: CancelToken) {
    let { keyword = '', contentType = '', page, complexity, isDneOnly, products, sortBy = SORT_BY_DEFAULT_KEY, isDevEnvOnly, language } = params;
    let queryParams = {
        key: keyword,
        pageNum: Number(page),
        pageSize,
        sortBy: [],
        filters: [
            {
                field: "contentSubtype",
                value: contentType.split(',')
            },
            ...projectsQueryCommonParamsFilters
        ]
    };

    if (products) {
        queryParams.filters.push({
            field: 'products',
            value: products.split(',')
        })
    }

    if (language) {
        queryParams.filters.push({
            field: 'language',
            //@ts-ignore
            value: language.split(',').map(lan => LANGUAGE_MAP[lan]).flat(),
        })
    }

    ['categories', 'industries'].forEach(field => {
        //@ts-ignore
        let value = params[field] as string;
        if (value) {
            value.split(',').forEach(val => {
                queryParams.filters.push({
                    field,
                    value: val.split(',')
                })
            })
        }
    });

    complexity && queryParams.filters.push({
        field: 'complexity',
        //@ts-ignore
        value: complexity
    });

    if (sortBy === "last_modified") {
        //@ts-ignore
        queryParams.sortBy.push({
            field: sortBy,
            desc: true
        })
    }

    if (isDevEnvOnly === 'true') {
        queryParams.filters.push({
            "field": "config.devEnv.id.keyword",
            "value": "",
            //@ts-ignore
            "exclude": true
        })
    }
    
    if (contentType === ContentSubTypes.TRACK && isDneOnly === 'true') {
        // params.isDneOnly = isDneOnly
        queryParams.filters.push({
            field: "isDneOnly",
            //@ts-ignore
            value: isDneOnly === 'true'
        })
    }

    if (aggregations) {
        //@ts-ignore
        queryParams.aggregations = aggregations;
    }

    let args = [api.projectsQuery, queryParams];//, { cancelToken }
    if (typeof cancelToken != typeof undefined) {
        //@ts-ignore
        args.push({ cancelToken })
    }
    //@ts-ignore
    return request.post(...args).then(res => {

        let { items, total, aggregations } = res.data as { items: any[], total: number, aggregations?: any };
        let pageCount = Math.ceil(total / pageSize)
        if (total === 0) {
            pageCount = 1
        }

        let dataList = items.map((item: any) => {
            if (item?.highlight) {
                let highlight = item?.highlight
                
                let lightName = highlight?.name?.join(" ")
                let lightDescription = highlight?.description?.join(" ")
                let lightContentFullText = highlight?.contentFullText?.slice(0, 1)
                if (!lightDescription) {
                    lightDescription = lightContentFullText
                }
                // @ts-ignore
                if (lightName) {
                    item.source.name = lightName;
                }
                // //@ts-ignore
                if (lightDescription) {
                    item.source.description = lightDescription;
                }
            }

            return item.source
        })

        return {
            total,
            pageCount,
            dataList,
            aggregations
        }
    })
}

export const defaultSearchResult: Omit<QueryResult, 'contentType'> = {
    dataList: [],
    pageCount: 1,
    total: 0
}

export function useSearchQuery(params: SearchUrlType) {
    const searchKey = queryString.stringify(params);
    const {contentType,isDneOnly,sortBy,page,...countKey}=params;
    const queryFn = useCallback(() => {
        return doQuery(params, [
            { "field": "products" },
            { "field": "language" }
        ]);
    }, [params]);

    const queryCountFn = useCallback(() => {
        return doQuery({...params,contentType:"track,module,lab"}, [
            { "field": "contentSubtype" },
            { "field": "isDneOnly" }
        ]);
    }, [params]);
    let { data, error } = useSWR(searchKey, queryFn, { revalidateOnFocus: false });

    const { data:countData, error:countDataError } = useSWR(queryString.stringify(countKey), queryCountFn, { revalidateOnFocus: false });

    const formateData=useMemo(()=>{
        if(data){
            const { aggregations={} } = data;
            const {products,language,contentSubtype,isDneOnly}=aggregations;
            const productsBuckets = products?.products?.buckets || [];
            const languageBuckets = language?.language?.buckets|| [];
    
            return [
                data,
                false,
                productsBuckets, 
                languageBuckets
            ]
    
        }
        return [defaultSearchResult, true, [], []]
    },[data])

    const formateCountData=useMemo(()=>{
        if(countData){
            const { aggregations={} } = countData;
            const {contentSubtype,isDneOnly}=aggregations;
    
            let buckets = contentSubtype?.buckets||contentSubtype?.contentSubtype.buckets;
            let isDneOnlyBuckets = isDneOnly?.isDneOnly.buckets;
            let labCount = buckets.find((bucket: any) => bucket.key === 'lab')?.doc_count || 0;
            let moduleCount = buckets.find((bucket: any) => bucket.key === 'module')?.doc_count || 0;
            let trackCount = isDneOnlyBuckets.find((bucket: any) => bucket.key_as_string === 'false')?.doc_count || 0;
            let expressCount = isDneOnlyBuckets.find((bucket: any) => bucket.key_as_string === 'true')?.doc_count || 0;
            let allCount = labCount + moduleCount + trackCount + expressCount;
            return {labCount,moduleCount,trackCount,expressCount,allCount}
        }
    },[countData])

    return [...formateData,formateCountData]
    
}



export function useWinSize() {
    const [size, setSize] = useState({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
    })

    const onResize = useCallback(() => {
        setSize({
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight
        })
    }, [])
    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [onResize]);


    return useMemo(() => {
        const isMobile = size.width <= 425;
        const isTablet = !isMobile && size.width <= 768;
        const isLaptop = !isTablet && size.width <= 1024;
        const isLgPC = size.width >= 1024;
        return [size.width, size.height, isMobile, {
            isMobile,
            isTablet,
            isLaptop,
            isLgPC
        }]
    }, [size.height, size.width]);
}

export const useLearningWidgetStyles = makeStyles(theme => ({
    dui: {
        width: '100%',
        height: 'calc(100vh - 33px)',

    },
    root: {
        // backgroundColor:"red"
    }
}));

export function useDevLearningWidget(selector: string, baseUrl: string) {

    const [currentTitle, setCurrentTitle] = useState('Cisco DevNet Learning Labs Center')
    const [currentDescription, setCurrentDescription] = useState('')

    const selectItemListener = useCallback((item) => {
        let headTitle = '',
            heaDescription = '';

        let pLabels = [
            // item.detail?.$label,
            item.detail?.$itemType === "COURSE-OVERVIEW" ? "Track Overview" : item.detail?.$label,
            item.detail?.$parent?.$label,
            item.detail?.$parent?.$parent?.$label,
            item.detail?.$parent?.$parent?.$parent?.$label
        ].filter(Boolean);
        pLabels.forEach((pLabel) => {
            headTitle += `${pLabel} - `;
        });
        setCurrentTitle(headTitle + currentTitle)

        let pLabelsCopy = pLabels.slice(0, -1);
        pLabelsCopy.forEach((pLabel) => {
            heaDescription += `${pLabel} - `;
        });
        let detail = item.detail,
            description = ''
        while (detail && !description) {
            let meta = detail.data?.meta
            if (meta) {
                description = meta?.slug || meta?.longDescription || meta?.description
            }
            detail = detail?.$parent
        }
        description = heaDescription + description
        // console.log('description:', description)
        setCurrentDescription(description)

        setCanonicalLink()

        let subUrl = item.detail.$unionUrlKey.split('/').filter((part: string) => {
            return part.indexOf('overview|') < 0;
        }).join('/');
        const targetUrl = `${process.env.REACT_APP_PUBLIC_URL}${baseUrl}${subUrl}/`.replace(/\/{1,}/g, '/');


        if (window.location.pathname === targetUrl) {
            return;
        }
        window.history.pushState(
            {},
            document.title,
            targetUrl
        );
        window?.AnalyticsSDK?.trackPageView();
    }, [baseUrl]);

    useEffect(() => {

        document.title = currentTitle
        const existingMetaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement;
        if (!existingMetaDescription) {
            const metaDescription = document.createElement('meta');
            metaDescription.name = 'description';
            metaDescription.content = currentDescription;
            document.head.appendChild(metaDescription);
        } else {
            existingMetaDescription.content = currentDescription;
        }
        // return () => {
        //     document.title = 'Cisco DevNet Learning Labs Center';
        //     existingMetaDescription.content ='Cisco DevNet Learning Labs Center'
        // };
    }, [currentTitle, currentDescription]);

    const history = useHistory();
    useEffect(() => {
        let unionUrlKey = '/' + history.location.pathname.split('/').slice(3, -1).join('/') + '/';
        window.postMessage({ action: 'select_item', unionUrlKey }, '*');
    }, [history.location.pathname]);

    function onErrorListener(evt: any) {
        let err = evt.detail;
        console.log('error', err);
        if (err && err.status === 404) {
            const pathname = document.location.pathname;

            for (let val of ERROR_PAGE_MAP) {
                if (pathname.startsWith(`/${process.env.REACT_APP_BASENAME}/${val[0]}`)) {
                    window.location.pathname = `${process.env.REACT_APP_PUBLIC_URL}${val[1]}/`;
                    return
                }
            }
            window.location.pathname = `${process.env.REACT_APP_PUBLIC_URL}/`
            // window.location.pathname = `${process.env.REACT_APP_PUBLIC_URL}/404/`
        }
    }
    useEffect(() => {
        const dom = document.querySelector(selector);
        if (!dom) return;
        dom.addEventListener('selectitem', selectItemListener);
        dom.addEventListener('error', onErrorListener);
        return () => {
            dom.removeEventListener('selectitem', selectItemListener)
            dom.removeEventListener('error', onErrorListener);
        }
    }, [selectItemListener, selector]);
}

