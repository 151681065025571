import React, { useCallback, useEffect, useState, useRef } from "react"
import "./RunCode.scss"
import videoJupyter from "./videos/Jupyter.webm"
import videoNSO from "./videos/NSO.webm"
import videoJupyter4 from "./videos/Jupyter.mp4"
import videoNSO4 from "./videos/NSO.mp4"
import { Link } from "react-router-dom";
import { useTrackVisibility } from 'react-intersection-observer-hook';

export default function RunCode() {
      const [ref, { isVisible }] = useTrackVisibility();

      const [isJupyterVideo, setIsJupyterVideo] = useState<boolean>(false);

      useEffect(() => {
            const intervalId = setInterval(() => {
                  // setIsJupyterVideo((prevIsJupyter) => !prevIsJupyter);
            }, 7000);

            return () => clearInterval(intervalId);
      }, [isJupyterVideo]);

      const handleFirstClick = useCallback(() => {
            setIsJupyterVideo(false);
      }, []);

      const handleSecondClick = useCallback(() => {
            setIsJupyterVideo(true);
      }, []);

      return <div className="runCode">
            <div className={`runCodeOpacityWrap `} ref={ref}>
                  <div className="runCodeTitle">Run code in the browser</div>
                  <div className="runCodeSubTitle">Learn Cisco APIs and technology with an integrated terminal or Jupyter notebooks</div>
                  <div className="runCodeWrap">
                        <div className="runCodeLeft">
                              <div className="runCodeLeftTitle">Try it yourself</div>
                              <div className={`runCodeLeftBtn ${!isJupyterVideo ? 'btnSelected' : ''}`} onClick={handleFirstClick}>
                                    <div className="runCodeLeftBtnIcon runCodeBtn1"></div>
                                    <div className="runCodeBtn">
                                          <div className="runCodeBtnTitle">Integrated Terminal</div>
                                          <div className="runCodeBtnWrap">
                                                <div className="runCodeBtnIcon"></div>
                                                NSO Basics
                                                <Link to={"/labs/nso-intro/introduction/"}>
                                                      <div className="runCodeBtnTry">Try it out</div>
                                                </Link>
                                          </div>
                                    </div>
                              </div>
                              <div className={`runCodeLeftBtn ${isJupyterVideo ? 'btnSelected' : ''}`} onClick={handleSecondClick}>
                                    <div className="runCodeLeftBtnIcon runCodeBtn2"></div>
                                    <div className="runCodeBtn">
                                          <div className="runCodeBtnTitle">Jupyter Notebook</div>
                                          <div className="runCodeBtnWrap">
                                                <div className="runCodeBtnIcon"></div>
                                                AppDynamics Cloud
                                                <Link to={"/labs/cloudconnections-jupyter/introduction/"}>
                                                      <div className="runCodeBtnTry">Try it out</div>
                                                </Link>
                                          </div>
                                    </div>
                              </div>

                        </div>

                        <div className={`runCodeRight ${isJupyterVideo ? 'showVideo' : ''}`}>
                              <video muted={true} playsInline loop autoPlay preload='auto' id="videoJupyter">
                                    <source src={videoJupyter} type="video/webm"></source>
                              </video>
                        </div>

                        <div className={`runCodeRight ${!isJupyterVideo ? 'showVideo' : ''}`}>
                              <video muted={true} playsInline loop autoPlay preload='auto' id="videoNSO">
                                    <source src={videoNSO} type="video/webm"></source>
                              </video>
                        </div>
                  </div>
                  <div className="runCodeMobileWrap">
                        <div className="runCodeMobileTop">
                              <div className={`runCodeMobileMenu ${!isJupyterVideo ? 'mobilebtnSelected' : ''}`}  onClick={handleFirstClick}>
                                    <div className="runCodeLeftBtnIcon runCodeBtn1"></div>
                                    Integrated Terminal
                              </div>
                              <div className={`runCodeMobileMenu ${isJupyterVideo ? 'mobilebtnSelected' : ''}`}   onClick={handleSecondClick}>
                                    <div className="runCodeLeftBtnIcon runCodeBtn2"></div>
                                    Jupyter Notebook
                              </div>
                        </div>
                        <div className="runCodeRightWrap">
                              <div className={`runCodeVideo ${isJupyterVideo ? 'showVideo' : ''}`}>
                                    <video muted={true} playsInline loop autoPlay preload='auto' id="videoJupyter">
                                          <source src={videoJupyter} type="video/webm"></source>
                                          <source src={videoJupyter4} type="video/mp4"></source>
                                    </video>
                                    <div className="runCodeBtnWrap">
                                          <div className="runCodeBtnIcon"></div>
                                          NSO Basics
                                          <Link to={"/labs/nso-intro/introduction/"}>
                                                <div className="runCodeBtnTry">Try it out</div>
                                          </Link>
                                    </div>
                              </div>

                              <div className={`runCodeVideo ${!isJupyterVideo ? 'showVideo' : ''}`}>
                                    <video muted={true} playsInline loop autoPlay preload='auto' id="videoNSO">
                                          <source src={videoNSO} type="video/webm"></source>
                                          <source src={videoNSO4} type="video/mp4"></source>
                                    </video>
                                    <div className="runCodeBtnWrap">
                                          <div className="runCodeBtnIcon"></div>
                                          AppDynamics Cloud
                                          <Link to={"/labs/cloudconnections-jupyter/introduction/"}>
                                                <div className="runCodeBtnTry">Try it out</div>
                                          </Link>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <Link to={"search/?contentType=lab&isDevEnvOnly=true&page=1"}>
                        <div className="blueBtnWrap">
                              <div className="blueBtn">
                                    Explore more interactive labs <div className="iconArrow"></div>
                              </div>
                        </div>
                  </Link>
            </div>
      </div>
}




