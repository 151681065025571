import React, { useEffect, useCallback, useState } from 'react';
import './search.scss';
import Tabs from './Tabs/Tabs';
import { ContentSubTypes,  SearchType, SearchTypes, searchTypes, useSearchQuery, useUrl } from './hooks';
import { SearchLayout } from './SearchLayout';
import { searchContext } from "./context";
import {  TitleAndDescribtion, usePageMetadata } from "../../utils";

// const tabs = [...Object.values(searchTypes)];
export default function Search() {
  const [tabs,setTabList]=useState([...Object.values(searchTypes)])
  usePageMetadata(TitleAndDescribtion.search.htmlTitle, TitleAndDescribtion.search.metaDescription);

  let { params, updateUrl } = useUrl();

  //auto redirect to ALL if none is selected
  useEffect(() => {
    if (!params.contentType) {
      updateUrl({ contentType: ContentSubTypes.ALL });
    }
  }, [params.contentType, updateUrl]);

  
  const currentTab = tabs.find(tab => tab.contentSubtype === params.contentType && tab.isDneOnly === params.isDneOnly)
    || tabs[0];
  const currentTabId = currentTab.id;

  let contentType = currentTab.contentSubtype;

  let [{ dataList, pageCount, total }, isLoading, productsBuckets, languageBuckets,count] = useSearchQuery(params);
  
  const [cachedPageCount, setCachedPageCount] = useState(1)
  useEffect(() => {
    if (!isLoading && pageCount !== cachedPageCount) {
      setCachedPageCount(pageCount);
    }
  }, [dataList, isLoading]);

  const [cachedTotal, setTotal] = useState(1)
  useEffect(() => {
    if (!isLoading && total !== cachedTotal) {
      setTotal(total);
    }
  }, [dataList, isLoading]);


  useEffect(()=>{
    if(!count) return;
    let { allCount, labCount, moduleCount, expressCount, trackCount } = count;
    const _tabs=tabs.map(tab => {
      let total = 0;
      switch (tab.contentSubtype) {
        case ContentSubTypes.ALL:
          total = allCount;
          break;
        case ContentSubTypes.LAB:
          total = labCount;
          break;
        case ContentSubTypes.MODULE:
          total = moduleCount;
          break;
        case ContentSubTypes.TRACK:
          total = tab.isDneOnly === 'true' ? expressCount : trackCount + expressCount;
          break;
      }
      return {...tab,total}
    });
    setTabList(_tabs)
  },[count])


  const onTabSelected = useCallback((tab: SearchType) => {
    
    if (tab === currentTab) {
      return;
    }
    if (tab?.id === SearchTypes.DNE) {
      tab.isDevEnvOnly = "false"
    }
    updateUrl({ isDneOnly: tab.isDneOnly, isDevEnvOnly: tab.isDevEnvOnly, contentType: tab.contentSubtype, page: '1' });

  }, [currentTab, updateUrl]);

  useEffect(() => {
    onTabSelected(currentTab);
  }, []);

  const onChangePageNum = useCallback((e, page) => {
    updateUrl({ page: String(page) })
  }, [updateUrl]);


  return (
    <searchContext.Provider value={{ productsBuckets, languageBuckets }}>
      <SearchLayout label="Search" {...{
        isLoading,
        dataList,
        pageCount: cachedPageCount,
        onChangePageNum,
        contentType,
        total: cachedTotal,
        categoriesMode: 'combine',
        productsBuckets
      }}>

        <Tabs tabs={tabs} onTabSelected={onTabSelected} selectedId={currentTabId} />

      </SearchLayout >
    </searchContext.Provider>

  )
}