import React, {
  Children, useRef, useState, useCallback, useEffect,
  ReactChild, ReactFragment, ReactPortal, PropsWithChildren,
} from 'react';

import { CircleArrow } from '../Icon';
import './index.scss';


function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitFor = 300,
): (...args: Parameters<F>) => void {
  let timeout: number;
  return (...args: Parameters<F>): void => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), waitFor);
  };
}

type Option = {
  slidesToShow: number;
  speed: number;
  infinite: boolean;
}

type Props = {
  slidesToShow?: number;
  speed?: number;
  infinite?: boolean;
  breakpoint?: { [index: number]: Partial<Option> };
  // pagination?: ((
  //   index: number, current: number, slideTo: (index: number) => void
  // ) => JSX.Element) | boolean;
  arrow?: boolean;
  refreshKey?: string;
  loading?: boolean;
  recommendId?: string
}

function Swiper({
  children = undefined,
  slidesToShow = 1,
  breakpoint = {},
  infinite = false,
  speed = 300,
  // pagination = false,
  arrow = true,
  refreshKey,
  recommendId = ''
}: PropsWithChildren<Props>): JSX.Element {
  const getOptions = useCallback((): Option => {
    const breakpoints = Object.keys(breakpoint)
      .map((key) => parseInt(key, 10)).sort((a, b) => b - a);
    const key = breakpoints.find((w) => window.innerWidth > w);
    if (key) {
      return ({
        infinite,
        slidesToShow,
        speed,
        ...breakpoint[key],
      });
    }
    return { infinite, slidesToShow, speed };
  }, [breakpoint, infinite, slidesToShow, speed]);

  const [current, setCurrent] = useState(0);
  const [diff, setDiff] = useState(0);
  const [option, setOption] = useState<Option>(getOptions);
  const wrapper = useRef(document.createElement('div'));
  const swiperBox = useRef(document.createElement('div'));

  const list = Children.toArray(children);

  useEffect(() => {
    setCurrent(0)
  }, [refreshKey])

  const goTo = useCallback((d: number) => {
    const { current: dom } = wrapper;
    const { current: boxDom } = swiperBox;
    if (boxDom.clientWidth < 792) {
      d = d/3
    }
    // console.log('boxDom.clientWidth', boxDom.clientWidth)
    if (option.infinite) {
      dom.classList.add('swiper__wrapper--transition');
      setCurrent((c) => c + d);
      setTimeout(() => {
        dom.classList.remove('swiper__wrapper--transition');
        setCurrent((c) => {
          if (c >= list.length || c < 1 - option.slidesToShow) {
            return list.length - Math.abs(c);
          }
          return c;
        });
      }, option.speed);
    } else {
      dom.classList.add('swiper__wrapper--transition');
      setCurrent((c) => Math.max(0, Math.min(c + d, list.length - option.slidesToShow)));
      setTimeout(() => {
        dom.classList.remove('swiper__wrapper--transition');
      }, option.speed);
    }

  }, [list.length, option, recommendId]);

  const getCard = (child: ReactChild | ReactFragment | ReactPortal, index: number): JSX.Element => (
    <div
      key={index}
      className="swiper__card"
      style={{ width: `${100 / option.slidesToShow}%` }}
      //style={{ width: `${100 / 4}%` }}
      onDragStart={(e) => e.preventDefault()}
    >
      {child}
    </div>
  );

  useEffect(() => {
    const onResize = debounce(() => setOption(getOptions));
    window.addEventListener('resize', onResize);

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, [getOptions]);

  // Touch&Mouse Events
  const start = useRef(false);
  const x = useRef(0);
  const p = useRef(0);
  useEffect(() => {
    const { current: dom } = wrapper;

    const onMouseDown = (event: MouseEvent): void => {
      if (event.button === 0) {
        start.current = true;
        x.current = event.clientX;
      }
    };
    const onMouseMove = (event: MouseEvent): void => {
      if (start.current) {
        p.current = event.clientX - x.current;
        setDiff(event.clientX - x.current);
      }
    };
    const onMouseUp = (event: MouseEvent): void => {
      if (!start.current) return;
      start.current = false;
      const width = (dom.clientWidth - 30) / 3;
      setDiff(0);
      if (Math.abs(event.clientX - x.current) > width / 2) {
        goTo(event.clientX - x.current > 0 ? -1 : 1);
      } else {
        dom.classList.add('swiper__wrapper--transition');
        setTimeout(() => {
          dom.classList.remove('swiper__wrapper--transition');
        }, option.speed);
      }
    };
    const onTouchStart = (event: TouchEvent): void => {
      start.current = true;
      x.current = event.changedTouches[0].clientX;
    };
    const onTouchMove = (event: TouchEvent): void => {
      if (start.current) {
        p.current = event.changedTouches[0].clientX - x.current;
        setDiff(event.changedTouches[0].clientX - x.current);
      }
    };
    const onTouchEnd = (event: TouchEvent): void => {
      start.current = false;
      const width = (dom.clientWidth - 30);
      const threshold = width / 8;
      p.current = 0;
      setDiff(0);
      if (Math.abs(event.changedTouches[0].clientX - x.current) > threshold) {
        goTo(event.changedTouches[0].clientX - x.current > 0 ? -1 : 1);
      } else {
        dom.classList.add('swiper__wrapper--transition');
        setTimeout(() => {
          dom.classList.remove('swiper__wrapper--transition');
        }, option.speed);
      }
    };

    if (list.length > option.slidesToShow) {
      dom.addEventListener('mousedown', onMouseDown);
      dom.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      dom.addEventListener('touchstart', onTouchStart);
      dom.addEventListener('touchmove', onTouchMove, { passive: true });
      dom.addEventListener('touchend', onTouchEnd);
    }

    return (): void => {
      dom.removeEventListener('mousedown', onMouseDown);
      dom.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      dom.removeEventListener('touchstart', onTouchStart);
      dom.removeEventListener('touchmove', onTouchMove);
      dom.removeEventListener('touchend', onTouchEnd);
    };
  }, [
    list.length, goTo, option,
  ]);

  useEffect(() => {
    const { current: dom } = wrapper;
    const checkClick = (e: Event) => {
      if (Math.abs(p.current) > 10) {
        e.preventDefault();
      }
      p.current = 0;
    };

    if (dom) {
      dom.addEventListener('click', checkClick);
    }

    return () => {
      dom.removeEventListener('click', checkClick);
    };
  }, [p]);

  let mobileDiff = 0;
  if (window.screen.width < 768) {
    if (current > 0 && current < list.length - 1) {
      mobileDiff = (window.screen.width - 300) / 2 - 8;
    }
    if (current === list.length - 1) {
      mobileDiff = window.screen.width - 300 - 16;
    }
  }
  const [gaAttribute, setGaAttribute] = useState({})
  useEffect(() => {
    const dynamicAttributes = recommendId ? {
      'data-track-name': 'recommendation:learning',
      'data-track-value': JSON.stringify({
        recommend_id: recommendId,
        event: 'switch',
      })
    } : {};
    setGaAttribute(dynamicAttributes)
  }, [recommendId])


  const [hideArrow, setHideArrow] = useState(false);
  useEffect(() => {
    if (!option.infinite && current <= 0 && current >= list.length - option.slidesToShow) {
      setHideArrow(true)
    }
  }, [option.infinite, current, list.length, option.slidesToShow])
  return (
    <div className="swiper" ref={swiperBox}>
      <div className="swiper__container">
        <div
          ref={wrapper}
          className="swiper__wrapper"
          style={{
            transform: `translateX(calc(-${(100 / option.slidesToShow) * (current + (option.infinite ? option.slidesToShow : 0))}% + ${mobileDiff + diff}px))`,
            transitionDuration: `${option.speed / 1000}s`,
          }}
        >
          {option.infinite && list.slice(list.length - option.slidesToShow).map(getCard)}
          {list.map(getCard)}
          {option.infinite && list.slice(0, option.slidesToShow + 1).map(getCard)}
        </div>
      </div>
      {(arrow && !hideArrow) && <div className="swiper__arrows" {...gaAttribute}>
        {list.length > 0 && (
          <CircleArrow title="Previous" onClick={(): void => goTo(-3)} className="swiper__arrow" disabled={!option.infinite && current <= 0} direction="left" />
        )}
        {list.length > 0 && (
          <CircleArrow title="Next" onClick={(): void => goTo(3)} className="swiper__arrow" disabled={!option.infinite && current >= list.length - option.slidesToShow} />
        )}
      </div>
      }
    </div>
  );
}

export default Swiper;
