import React from "react"
import { Link } from "react-router-dom";
import { NoLearningContentTip,NoInProgressLearningContentTip } from "./const"
import useGetUserInterests from "../../../../requestHooks/useGetUserInterests"
import useGetStartNowContent from "../../../../requestHooks/useGetStartNowContent"
import { useGetUserProgress } from "../../../../requestHooks/index"
import noHistoryImg from "../../../../assets/img/noHistory.svg"
import noInProgressHistory from "../../../../assets/img/noInProgressHistory.svg"

import Swiper from "../../../../components/Swiper"
import Card from "../../../../components/Card"
import NewContent from "../../../../components/newContent"
import Nav from "../../../../components/Nav"
import Spinner from '../../../../components/Spinner';
import PopularCards from "../../../../components/PopularContent";
import Chevron from "../../../../components/Icon/Chevron"
import { PROGRESS_IN_PROGRESS, PROGRESS_COMPLETED } from "../../../../const"
import { project } from "../../../../types"
import "./index.scss"

function NoLearningContent() {
  const { isLoading, data } = useGetStartNowContent();
  return <div className="search-for-you container">
    <div className="search-for-you_login">
      <img src={noHistoryImg}></img>
      <p>{NoLearningContentTip}</p>
    </div>
    <div className="search-for-you_list">
      <div className="search-for-you_list_title">Start with a curated list of learning content</div>
      {isLoading && <div className="list-loading" style={{ minHeight: 350 }}><Spinner size="big" /></div>}
      {!isLoading && data.length > 0 && <ul className="search-for-you_list_content">
        {data.map((_: project) => {
          return <li className="search-for-you_list_content_item">
            <a className="hover_underline" href={_.url} target="_blank">{_.name}</a>
            <p>{_.description}</p>
          </li>
        })}
      </ul>}
    </div>
  </div>
}

function NoInProgressLearningContent() {
  return <div className="search-for-you_no-in-progress">
    <img src={noInProgressHistory}></img>
    <p>{NoInProgressLearningContentTip}</p>
  </div>
}


function LearningContent() {
  const { isLoading, progress, projects, total } = useGetUserProgress({ pageSize: 12, status: PROGRESS_IN_PROGRESS })
  const { total: completeTotal, isCompleteFetching } = useGetUserProgress({ pageSize: 1, status: PROGRESS_COMPLETED, enabled: typeof total === "number" && total == 0 })

  const _isLoading = isLoading || isCompleteFetching

  return <div className="search-for-you_labs_content container">
    {_isLoading && <div className="list-loading" style={{ minHeight: 350 }}><Spinner size="big" /></div>}
    {!_isLoading && (total > 0 || completeTotal > 0) && <>
      <div className="search-for-you_left-learning">
        <p className="search-for-you_labs_title">Pick up from where you left off</p>
        <Link to="/mylearning" className="search-for-you_view-history">View my learning<Chevron direction="right" color="#fff"></Chevron></Link>
      </div>
      {total > 0&&<div className={`search-for-you_list_wrap`}>
        <Swiper slidesToShow={1} breakpoint={{ 821: { slidesToShow: 4 } }}>
          {projects.map((item: project) => {
            const { code_name, contentSubtype } = item;
            const p = (progress[contentSubtype] || {})[code_name]
            return <Card key={item.id} data={item} progress={p}></Card>
          })
          }</Swiper>
      </div>}
      {completeTotal > 0&&<NoInProgressLearningContent></NoInProgressLearningContent>}
    </>}
    {!_isLoading && projects.length === 0 && completeTotal === 0 && <NoLearningContent />}
  </div>
}




function PopularContent() {
  return <div className="search-for-you_labs_content container">
    <p className="search-for-you_labs_title">Trending labs in our community</p>
    <PopularCards></PopularCards>
  </div>
}

function UserInterests() {
  const { data, isLoading, progress } = useGetUserInterests()

  return <div className="search-for-you_labs_content container">
    <p className="search-for-you_labs_title">We think you might like these</p>
    <div className={`search-for-you_list_wrap ${isLoading ? 'list-loading' : ''}`}>
      {isLoading && <Spinner size="big" />}
      {data?.interests && <Swiper slidesToShow={1} breakpoint={{ 821: { slidesToShow: 4 } }} recommendId={data?.recommend_id || ''}>
        {data?.interests.map((item: project) => {
          const p = (progress[item.contentSubtype] || {})[item.code_name]
          return <Card progress={p} key={item.id} data={item} recommendId={data?.recommend_id || ''}></Card>
        })}
      </Swiper>
      }
    </div>
  </div>
}



const ForYou = () => {
  return (
    <div>
      <div className="search-for-you_labs" style={{ paddingTop: 0, paddingBottom: 60, zIndex: 18, position: 'relative' }}>
        <LearningContent></LearningContent></div>
      <div className="search-for-you_labs blue-bg recommendWrap" style={{ background: "#EFFCFF", zIndex: 17 }}>
        <PopularContent></PopularContent>
      </div>
      <div className="search-for-you_labs recommendWrap" >
        <UserInterests></UserInterests>
      </div>
      <NewContent></NewContent>
      <Nav></Nav>
    </div>
  );
};

export default ForYou


