import { useEffect, useState } from "react";
import selfFetch, { FetchResult } from '../../../utils/selfFetch'
import {isLoginedIn} from "../../../utils"



export interface Data {
    "last_modified": string,
    "nps": boolean
} 


function fetchNPS(cb: (data: FetchResult<Data>) => void) {
    const url = process.env.REACT_APP_LEARNING_CENTER_NPS_API;
    setTimeout(()=>{
        selfFetch<Data>(url!, cb, {
            method: 'get'
        })
    },1000)

}

export default function useFetchNPS(depend: unknown[]) {
    const [nps, setNPS] = useState<Data | null>(null);
    useEffect(() => {
        if(isLoginedIn()){
            fetchNPS((result) => {
                setNPS(result.data)
            })
        }
    }, depend)

    return { nps }
}