import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {progress} from "../../../../types"
import "./index.scss"
import PercentageDetail,{Percentage} from './percentageDetail';
import {LAB,MODULE,TRACK,STEP} from "../../const"
import {useGetProgressDetail} from "../../requestHooks"
import Icon from '../../../../components/Icon';
import Chevron from '../../../../components/Icon/Chevron';
import dayjs from 'dayjs'


type Props={
    progress:progress,
    data:any,
    percentageDetail?:Percentage[]
}

type ModuleItem={
    name:string,
    code_name:string,
    id:string,
    url:string,
    submodules:Item[]

}

type Item={
    title: string,
    url: string,
    key:string,
    id:string,
    type:"markdown"|"learning lab"
    items:Item[]
}

type Step={
    id:string,
    content:string, 
    title:string,
    url:string
}


export default function ListCard(props:Props){
    const {data,progress}=props
    const {contentSubtype,name,description,tags,url,code_name}=data;
    const {percentage,last_modified_at}=progress;
    const [detailOpen,setDetailOpen]=useState(false);
    const [detailCodeName,setDetailCodeName]=useState(code_name)
    const [detailType,setDetailType]=useState(contentSubtype)

    const btnRef=useRef(null);

    const {detail,detailMap}=useGetProgressDetail({type:detailType,code_name:detailCodeName,show:detailOpen})

    const [latestModule,latestLab,latestStep]=useMemo(()=>{
        if(detail?.activities){
            const {activities}=detail;
            const latest:{[moduleId:string]:{[labId:string]:string}}=(activities[0]?.event?.latest)||{};
            const latestItem=Object.entries(latest)[0];
            const latestItemValue=Object.entries(latestItem[1])[0]||[];
            return [latestItem[0],latestItemValue[0],latestItemValue[1]]
        }
        return []
    },[detail?.activities])

    const percentageDetail=useMemo(()=>{
        if(contentSubtype===LAB){
            return data.steps.map((item:Step,index:number)=>{
                const p=progress.progress_event.viewed_steps.includes(index+1)?100:0;
                return {
                    name:item.title,
                    id:item.id,
                    type:STEP,
                    percentage:p,
                    url:`${url}${item.url}`
                }
            })
        }

        if(contentSubtype===MODULE){
            const labs=data.submodules;
            if(labs){
                return labs.reduce((arr:Percentage[],item:Item)=>{
                    return [...arr,...item.items.map((lab)=>{
                        const detail=detailMap[code_name]||{}
                        const detailItem=detail[lab.id]||{};
                        return {
                            name:lab.title,
                            id:lab.id,
                            type:LAB,
                            percentage:detailItem?.progress?.percentage||0,
                            url:`${url}${lab.id}${latestLab===lab.id?`/step/${latestStep}`:""}`
                        }
                    })]
                },[])
            }
        }

        if(contentSubtype===TRACK){
            const {modules}=data;
            return modules.map((item:ModuleItem)=>{
                const moduleCodeName=item.code_name
                const labs=item.submodules;
                const detail=detailMap[code_name]||{}
                const detailItem=detail[moduleCodeName]||{};
                const baseUrl=`${url}${moduleCodeName}`
                return {
                    id:moduleCodeName,
                    name:item.name,
                    type:MODULE,
                    url:baseUrl,
                    percentage:detailItem?.progress?.percentage||0,
                    items:labs.reduce((arr:Percentage[],_)=>{
                       return [...arr,
                               ..._.items.map(lab=>{
                                const detail=detailMap[moduleCodeName]||{}
                                const detailItem=detail[lab.id]||{};
                                const isLatest=moduleCodeName===latestModule&&lab.id===latestLab;
                                const url=`${baseUrl}/${lab.id}${isLatest?`/step/${latestStep}`:""}`
                                return {id:lab.id,name:lab.title,url,type:LAB,percentage:detailItem?.progress?.percentage||0}
                                })]
                    },[])
                }
            })
            
        }
        return []
    },[percentage,data,detailMap,latestModule,latestLab,latestStep])
    const subtype=`${contentSubtype[0].toUpperCase()}${contentSubtype.slice(1)}`
    const typeName=`Learning ${subtype}`

    useEffect(()=>{
        function handleClick(e:MouseEvent){
            const target=e.target;
            if((target!==btnRef.current)){
                setDetailOpen(false) 
            }
        }
        document.addEventListener("click",handleClick);
        return ()=>{
            document.removeEventListener("click",handleClick);
        }
    },[])


    
    useEffect(()=>{
        if(latestModule){
            if(contentSubtype===TRACK){
                setDetailCodeName(latestModule);
                setDetailType(MODULE)
            }
        }
    },[latestModule])
    

    const handleMouseEnter:React.MouseEventHandler<HTMLDivElement>=useCallback((e)=>{
        e.stopPropagation()
        setDetailOpen(true)
    },[])

    const handleMouseLeave=useCallback(()=>{
        setDetailOpen(false)
    },[])

    function handleDetailClick(e:React.MouseEvent){
        e.nativeEvent.stopPropagation()
    }

    function handleDetailItemClick(type:string,id:string){
        setDetailCodeName(id),
        setDetailType(type)
    }


    return <div className={`learning-list-card learning-list-card_${contentSubtype}`}>
        <div className='learning-list-card_img'>
            <span></span>
            <div className='learning-list-card_img_name'>{typeName}</div>
        </div>
        <div className='learning-list-card_content_wrap'>
            <div style={{display:'flex',alignItems:'center'}}>
            <div className='learning-list-card_content'>               
                <a target='_blank' href={url} className='learning-list-card_content_name hover_underline'>{name}</a>
                <p className='learning-list-card_content_desc'>{description}</p>
                <ul className='learning-list-card_content_tags'>
                    {tags.map((item:string)=>{
                        return <li>{item}</li>
                    })}
                </ul>
            </div>
            
            <div className='learning-list-card_progress' onMouseLeave={handleMouseLeave}  onMouseOver={handleMouseEnter}>
                <div className='learning-list-card_progress_wrap'>
                    <p className='learning-list-card_progress_num'><span>Complete:</span><span className='learning-list-card_progress_num_content'>{percentage+'%'}<Chevron direction={detailOpen?'up':'down'}></Chevron></span></p>
                    <div className='learning-list-card_progress_percentage'>
                        <span style={{width:percentage+'%'}}></span>
                    </div>
                </div>
                
                <div onClick={handleDetailClick} className={`learning-list-card_progress_detail ${detailOpen?"open":""}`}>
                    <div>
                    <div className='learning-list-card_progress_detail_title'>LEARNING PROGRESS</div>
                    <PercentageDetail defaultOpenItem={detailCodeName} id={code_name} onChange={handleDetailItemClick} list={percentageDetail} type={contentSubtype}></PercentageDetail>
                    </div>
                </div>
            </div>
            </div>
            <div>
                <a target='_blank' href={url} className='learning-list-card_button'>
                    {percentage===100?"Retake":"Continue"} {subtype}
                    <Icon type="internal-link" size={18}></Icon>
                </a>
                <p className='learning-list-card_update-time'>Last visited: {dayjs(last_modified_at).format('MMM D,YYYY')}</p>
            </div>
        </div>
    </div>
}