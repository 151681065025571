import React from "react";
import { LabCard, ModulCard, CourseCard } from "../index";
import { progress, project } from "../../types"
import { TRACK, LAB, MODULE } from "../../const"

type Props = {
    data: project,
    progress?: progress,
    recommendId?: string
}
export default function Card(props: Props) {
    const { data, progress, recommendId = '' } = props;
    // console.log("Card props:", props)
    const { contentSubtype } = data
    switch (contentSubtype) {
        case TRACK:
            return <CourseCard data={data} progress={progress} recommendId={recommendId}></CourseCard>;
        case MODULE:
            return <ModulCard data={data} progress={progress} recommendId={recommendId}></ModulCard>;
        case LAB:
            return <LabCard data={data} progress={progress} recommendId={recommendId}></LabCard>;
        default:
            return null
    }
}