import {Data as DataType} from "./useFetchNps"
import {useEffect} from 'react'
import {TRIGGER_EVENT_NAME,setFormMetadata as _setFormMetadata} from "../../NPS"
import { useLocation} from 'react-router-dom';


const paramsType=['tracks','modules','labs']
const keys=['trackId','moduleId','labId']
type Keys=['trackId','moduleId','labId']

type ToObj<T extends Array<string>>={
    [key in T[number]]:string
}
type ParamsType=ToObj<Keys>

export function getNpsData(){
    const {pathname}=window.location;
    const parts=pathname.replace(/(^\/learning\/)|(\/$)/g,'').split("/");
    let start=paramsType.indexOf(parts[0]);
    const npsData=keys.reduce((obj,key)=>{
        obj[key]=''
        return obj
    },{} as any)

    if(start>-1&&parts.length>1){
        let j=1;
        for(let i=start;i<keys.length&&j<parts.length;i++){
            npsData[keys[i]]=parts[j];
            j++
        }
    }
    
    return npsData as ParamsType
}

function triggerNps(){
    const event=new CustomEvent(TRIGGER_EVENT_NAME);
    event.initCustomEvent(TRIGGER_EVENT_NAME,true,true,undefined)
    document.dispatchEvent(event)
}

function setFormMetadata(){
    _setFormMetadata(getNpsData(),process.env.REACT_APP_NPS_ID!)
}

export default function useTriggerNps(nps:DataType|null){
    const {pathname}=useLocation()
    
    useEffect(()=>{
        setFormMetadata()
    },[pathname])

    useEffect(()=>{
        document.addEventListener('selectitem',setFormMetadata)
        return ()=>{
            document.removeEventListener('selectitem',setFormMetadata)
        }
    },[])
    
    useEffect(()=>{
        if(nps){
            if(nps.nps){
                triggerNps()
            }
        }
    },[nps])
}