import React, { useCallback, useEffect, useState, useContext, useMemo } from "react"
import { toggleItemsInArray } from "../../../typings/shared";
import { TagsProps, Tag, Category } from "./TechTags";
import { searchContext } from "../context"
import { request, api } from '../../../utils/request';

const ProductTags: React.FC<TagsProps> = function (props) {
    const { productsBuckets = [] } = useContext(searchContext)
    let { selectedTagStrArr = [], onTagClicked } = props;
    const [productTagsArr, setProductTagsArr] = useState<Category[]>([]);

    useEffect(() => {
        request(api.productTags).then((res) => {
           
            if (res.data?.items?.length) {
                let allItems = res.data.items;
                //@ts-ignore
                let allProductTags=allItems.map((item)=>{
                    return{
                        label:item.name,
                        value:item.name,
                    }
                })
               
                setProductTagsArr(allProductTags)

            }
        })
    }, [])


    const productTags = useMemo(() => {
        return productTagsArr.map(item => {
            const bucket = productsBuckets.filter(b => {
                return b.key == item.label
            })[0]
            return {
                ...item,
                value: item.label,
                count: bucket ? bucket.doc_count : 0,
                selected: selectedTagStrArr?.some(str => str === item.label)
            }
        }).filter((_)=>_.count>0) as Category[]
    }, [productsBuckets, selectedTagStrArr, productTagsArr])


    const selectedTags = useMemo(() => {
        return productTags.filter(tag => selectedTagStrArr?.some(str => str === tag.value))
    }, [productTags])

    //const [selectedTags, setSelectedTags] = useState<Category[]>(productTags.filter(tag => selectedTagStrArr?.some(str => str === tag.value)));

    const thisOnTagClicked = useCallback((tag) => {
        toggleItemsInArray(selectedTags, [tag]);

        onTagClicked && onTagClicked(tag, selectedTags);
    }, [onTagClicked, selectedTags]);


    //const selectedTagStr = selectedTagStrArr.sort().join(',');
    // useEffect(() => {
    //     const arr = selectedTagStr.split(',');
    //     setSelectedTags(productTags.filter(pt => arr.indexOf(pt.value) > -1));
    // }, [selectedTagStr]);
    return (
        <div className='product-tags'>
            {productTags.length===0&&<p className='tags_unavailable'>None available</p>}
            {
                productTags.length>0&&productTags.map(tag => {
                    return <Tag key={tag.value} data={tag} selectedTags={selectedTags} onTagClicked={thisOnTagClicked} />
                })
            }
        </div>
    )
}
export default ProductTags;
