// export const BANNER_TITLE='Dive deep into Cisco technologies and get hands-on with learning labs'
export const BANNER_TITLE = 'Get to know Cisco technologies with '
export const BANNER_SUBTITLE0 = 'Learn about Cisco product suites, explore use cases, and develop your skills with interactive tutorials and built-in development environments'
export const BANNER_SUBTITLE = 'Select your area of interest'
export const TRACK_SUBTYPE = "track"

export const TopTags = [
    'Security',
    'Cloud',
    'IoT',
    'Collaboration',
    'Data Center',
    'Networking',
]
export const TRACK_API = process.env.REACT_APP_API_HOST + "/v1" + '/foundation-search/catalogs/query?type=' + process.env.REACT_APP_SEARCH_TYPE

export const GetStarted = {
    Title: "Get started and try our Learning Labs with up-level IDEs ",
    p1:"The DevNet Learning Labs are the next generation learning experience, developed to help you study.",
    p2:"Our built-in development environments enable you to practice coding throughout your learning journey.",
    
}
