import { useCallback, useEffect, useMemo, useState } from 'react';
import useInstallPlatformNps from "./useInstallPlatformNps"


enum FormKeys{
  feedback='feedback',
  score='score'
}

interface ActivityAction<T>{
  associate_id: string
  created_at: string
  created_by: string,
  data:Record<string,{name:string,type:keyof typeof FormKeys,value:string}>,
  id:string,
  type:T
}
interface Activity{
  decline?:ActivityAction<'decline'>,
  open?:ActivityAction<'open'>,
  submit?:ActivityAction<'submit'>,
  'decline::notification'?:ActivityAction<'decline::notification'>,
  'open::notification'?:ActivityAction<'open::notification'>,
}

function getLatestActivity(origin:Activity['decline']|Activity['open'],notification:Activity['decline::notification']|Activity['open::notification']){
  if(!origin){
    return notification;
  }else{
    if(notification){
      if(new Date(notification.created_at).getTime()>new Date(origin.created_at).getTime()){
        return notification
      }
    }
  }
  return origin

}

function dataProcess(data:any){
  if(!data)return data
  const _data={...data};
  const notificationDecline=data['decline::notification']
  const notificationOpen=data['open::notification']
  const {open,decline}=_data

  _data.open=getLatestActivity(open,notificationOpen)
  _data.decline=getLatestActivity(decline,notificationDecline)

  return _data
}

export function setFormMetadata(data:unknown,id:string){
  if(window.NpsSDK){
      const form=window.NpsSDK.getForm(id)
      if(form){
        form.setFormMetadata(data)
      }
  }
}

export default function usePlatformNps(id:string,env:string,npsData:any,getFormMetadata?:()=>any) {
  const [score, setScore] = useState('')
  const [open, setOpen] = useState(false)
  const [notificationOpen, setNotificationOpen] = useState(false)
  const [loadFormError,setLoadFormError]=useState(false)
  const [activity,setActivity]=useState<Activity|null>(null)
  const [form,setForm]=useState<any>(null)

  const installed=useInstallPlatformNps(env)

  function beforeOpen(){
    if(typeof getFormMetadata=="function"){
      setFormMetadata(getFormMetadata(),id)
    }
  }


  useEffect(()=>{
    if(npsData){
      setFormMetadata(npsData,id)
    }
  },[npsData])
  
  useEffect(()=>{
    if(installed&&window.NpsSDK){
        
            window.NpsSDK.loadForm(id,{
                    type: 'slide', 
                    language: 'en',
                    thresholdScore: 9,
                    thanksMessage: {
                        message: {
                            en: 'Thank you for your feedback！',
                            zh: '感谢您的反馈意见！'
                        }
                    },
                    callback: (form:any) => { 
                        if(!form){
                          setLoadFormError(true)
                          return
                        } 
                        setForm(form)
                    }, 
                }
            )
    }
  },[installed,id])

  const getActivity=useMemo(()=>{
    if(form){
      return function getActivity(){
        form.getUserNPSActivity().then((res:{data:Activity}) => {
          setActivity(dataProcess(res.data))
        }).catch((err:Error)=>{
          setActivity(null)
        });
      }
    }
    return null
  },[form])

  useEffect(()=>{
    if(form){
      form.onOpenForm=()=>{
        setOpen(true)
      }
      form.onOpenNotification = function() { setNotificationOpen(true) }
      form.onCloseNotification = function() { setNotificationOpen(false)}
    }
  },[form])

  useEffect(()=>{
    if(form&&getActivity){
      form.onCloseForm=()=>{
        form.getFormData((data:any)=>{
          
          if(data.formData){
            for(let val of data.formData){
              if(val.type===FormKeys.score){
                setScore(val.value)
                if(val.value){
                  getActivity()
                }
              }
            }
          }
          
        })
        setOpen(false)
      }
    }
  },[form,getActivity])


  const openNps=useCallback(()=>{
    if(window.NpsSDK && form){
      beforeOpen()
      window.NpsSDK.openSurvey(id);
    }
  },[form,id])

  const openNpsNotification=useCallback(()=>{
    if(window.NpsSDK && form){
      beforeOpen()
      window.NpsSDK.getForm(id)?.openNotification()
    }
  },[form,id])

  return { score, open, openNps,activity,getActivity,loadFormError,notificationOpen,openNpsNotification}
}