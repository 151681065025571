import React, { useState, KeyboardEvent, ChangeEvent, useMemo, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { ContentSubTypes } from '../../../Search/hooks';
import { SORT_BY_NAMES } from "../../../Search/const"
import { TopTags } from "../../const"
import SearchSuggest from '../../../../components/SearchSuggest/SearchSuggest'
import SearchInput from '../../../Search/SearchInput/SearchInput'
import './index.scss'


export default function Search() {
    const [keyword, setKeyword] = useState("");
    const [stopListenInputEnter, setStopListenInputEnter] = useState(false);
    const history = useHistory();

    const searchParams = useMemo(() => {
        return `?contentType=${ContentSubTypes.ALL}${keyword ? `&keyword=${keyword}&sortBy=${SORT_BY_NAMES.luceneScore}` : ''}`
    }, [keyword])

    function changeKeyword(event: ChangeEvent<HTMLInputElement>) {
        setKeyword(event.target.value)
    }

    function searchEnter(e: KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 40 || e.keyCode === 38) {
            setStopListenInputEnter(true)
        }
        if (e.keyCode === 13 && !stopListenInputEnter) {
            // console.log("searchEnter", keyword)
            routerSearch(searchParams)
        }
    }
    function routerSearch(searchWords: string) {
        history.push({
            pathname: `/search/`,
            search: searchWords
        })

    }
    const [focus, setFocus] = useState(false)
    const onFocus = useCallback(e => {
        setFocus(true)
    }, []);

    const onBlur = useCallback(e => {
        setTimeout(() => {
            setFocus(false)
        }, 200)
    }, []);

    const onSearch=useCallback((key:string)=>{
        history.push({
            pathname: `/search/`,
            search: `?contentType=${ContentSubTypes.ALL}${key ? `&keyword=${key}&sortBy=${SORT_BY_NAMES.luceneScore}` : ''}`
        })
    },[])

    return <>
        <div className='topSearch'>
            <div className="inputWrap">
                {/* <input type="text" className='topSearchInput' placeholder="Search Learning Labs" value={keyword} onChange={changeKeyword} onKeyUp={searchEnter} onFocus={onFocus} onBlur={onBlur} />
                <SearchSuggest searchWord={keyword} showSuggest={focus} selectSuggest={(words) => {
                    routerSearch(`?contentType=${ContentSubTypes.ALL}${words ? `&keyword=${words}&sortBy=${SORT_BY_NAMES.luceneScore}` : ''}`)
                }
                }></SearchSuggest> */}
                <SearchInput onSearch={onSearch}></SearchInput>
                <div className="topSearchTagWrap" >
                    {
                        TopTags.map((tag) => (
                            <Link key={tag} to={`./search/categories/${encodeURIComponent(tag)}/`}>
                                <div className="topSearchTag hoverUnderLine">{tag}</div>
                            </Link>
                        ))
                    }
                </div>
            </div>
            {/* <Link to={`/search/${searchParams}`}>
                <div data-variant="contained" className='topSearchBtn'>
                    Search
                </div>
            </Link> */}
        </div>
    </>
}