import React from "react"
import './index.scss';

export type Size = 'tiny' | 'small' | 'medium' | 'big';

type Props = {
  size?: Size;
  inline?: boolean;
  white?: boolean;
}

function Spinner({ size = 'small', inline = false, white = false }: Props): JSX.Element {
  return (
    <div className={`spin${inline ? ' spin--inline' : ''}`}>
      <i className={`spin__icon spin__icon--${size}${white ? ' spin__icon--white' : ''}`} />
    </div>
  );
}

export default Spinner;
