import { useEffect, useState } from "react";
import { TRIGGER_EVENT_NAME } from "../const"


export default function useTriggerNps() {
    const [triggerCount, setTriggerCount] = useState(0)
    const [npsData,setNpsData]=useState<any>(null)
    //@ts-ignore
    function triggerEvent(e) {
        if(e.detail?.npsData){
            setNpsData(e.detail?.npsData) 
        }
        setTriggerCount(triggerCount => triggerCount + 1)
    }
    useEffect(() => {
        window.addEventListener(TRIGGER_EVENT_NAME, triggerEvent);
        return () => {
            window.removeEventListener(TRIGGER_EVENT_NAME, triggerEvent)
        }
    }, [])

    return [triggerCount,npsData] as const
}