import React from 'react';

import { useParams } from 'react-router-dom';
import { useDevLearningWidget, useLearningWidgetStyles } from '../Search/hooks';

const Lab = () => {
  const classes = useLearningWidgetStyles();
  let { labId: id, step_id, step_url_id } = useParams();
  let props = {
    ratingformid: process.env.REACT_APP_RATING_FORM_ID,
    envparams: JSON.stringify({
      custom_base: process.env.REACT_APP_JUPYTER_CUSTOM_BASE
    })
  }
  if (step_url_id) {
    props.selecteditemkey = `/${step_url_id}`
  } else if (step_id) {
    props.step = step_id
  }

  useDevLearningWidget('dwc-llab-ui', '/labs/' + id);

  return (
    <div
      className={classes.root}
    >
      <dwc-llab-ui class={classes.dui} labid={id} status={process.env.REACT_APP_CONTENT_STATUS} community="true" {...props}>
        <style>{`@import url('${process.env.REACT_APP_ASSET_URL_BASE}/form/styles/sdk.css')`}</style>
      </dwc-llab-ui>
    </div>

  );
};

export default Lab;
