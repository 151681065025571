import { useQueries } from "@tanstack/react-query";
import { api, request } from "../utils/request";
import { useMemo } from "react";
import {isLoginedIn} from "../utils"
import {project,progress} from "../types"

type List={
    code_name:string,
    contentSubtype:string
    // contentSubtype:"lab"|"module"|"track"
}[]

export default function (list:List){

    const listMap=useMemo(()=>{
        const map:{[key:string]:string[]}={};
        list.forEach(item=>{
            const type=item.contentSubtype;
            map[type]=map[type]||[];
            map[type].push(item.code_name)
        })
        return map
    },[list])
    
    const dataList = useQueries({
        queries:Object.entries(listMap).map((item)=>{
            return {
                queryKey: ['getProgressByCodeNameList', ...item[1]],
                queryFn: async() => {
                    if(!isLoginedIn()) return []
                    let url='';
                    switch(item[0]){
                        case "lab":
                            url= api.labsProgress;
                            break;
                        case "module":
                            url= api.modulesProgress;
                            break;
                        case "track":
                            url= api.coursesProgress;
                            break;
                    }
                    const response = await request.post(url,item[1]);
                    return [[item[0]],response.data.data];
                },
                refetchOnWindowFocus: false
            }
        })
    })

    const data=useMemo(()=>{
        const map:{[key:string]:{[key:string]:progress}}={}
        dataList.forEach(_=>{
            const data=_.data||[];
            const type=data[0];
            const list=data[1]||[]
            list.forEach((_:progress)=>{
                map[type]=map[type]||{};
                map[type][_.code_name]=_
            })
        })
        return map
    },[dataList])

    return data 
}