import axios from 'axios';

export const request = axios.create(
    {
        withCredentials: true
    }
);
const url = process.env.REACT_APP_API_HOST + "/v1";
const envStatus = process.env.REACT_APP_CONTENT_STATUS;
// ======for stage env mooc======
// const url = 'https://devnet-testing.cisco.com/v1';
// const envStatus = 'pending'

// https://devnet-testing.cisco.com/v1/lpcoursemgmt/courses
// https://devnet.cisco.com/v1/devnetpath/pathfulls?Type=devnet.path

//https://devnet-testing.cisco.com/v1/foundation-search/projects/learninglab/indexes/labcontent_v3,modulecontent_v3,trackcontent_v3/query
export const api = {
    projectsQuery:url + "/foundation-search/projects/learninglab/indexes/labcontent_v3,modulecontent_v3,trackcontent_v3/query",
    query: url + '/foundation-search/catalogs/query?type=' + process.env.REACT_APP_SEARCH_TYPE + '&extendHit=true',
    tags: url + '/metadata/catalogs/tags/search?type=Categories&sortBy=name%20asc',
    // productTags: url + '/metadata/catalogs/tags/search?type=Products-Group&sort=name&order=asc',
    productTags: url + '/metadata/catalogs/tags/search?type=Products-Group&sortBy=name%20asc',
    startNow: 'https://devnet.cisco.com/v1/devnetpath/pathfulls?Type=devnet.path',
    coursesProgress: url + '/coursemanagement-community/community/course/progress/' + envStatus,
    modulesProgress: url + '/coursemanagement-community/community/module/progress/' + envStatus,
    labsProgress: url + '/coursemanagement-community/community/item/progress/learninglab/' + envStatus,
    userProgress: url + '/coursemanagement-community/community/learning/progress?state='+envStatus,
    suggest2: url + '/foundation-search/catalogs/suggest2?type=learninglab',
    popular: url + '/recommend/popular',
    userInterests: url + '/recommend/user-interests',
    recommendationFeedback: url + '/events/devnet_recommendation_feedback',

    //may not used,need confirm
    courses: url + 'lpcoursemgmt/author/courses',
    histories: url + 'lpcoursemgmt/author/histories',
    approvals: url + 'lpcoursemgmt/author/approvals',
    approvalsAdmin: url + 'lpcoursemgmt/admin/approvals',
    approvalSubmit: url + 'lpcoursemgmt/author/approvals',
    getTags: url + 'hitag/tags',
    pictures: url + 'llabmedia/pictures',
    videos: url + 'llabmedia/videos',
    progress: url + 'lpcoursemgmt/user/progress',
    labs: url + 'lllabmgmt/labs/testing',
    modules: url + 'devnetpath/pathfulls?Type=devnet.path',
}


