import React from 'react'
import './index.scss';

type Props = {
  direction?: 'left' | 'right';
  title?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

function CircleArrow({
  direction = 'right',
  title = undefined,
  onClick = undefined,
  className = '',
  disabled = false,
}: Props): JSX.Element {
  return (
    <i role="button"
      tabIndex={onClick ? 0 : -1}
      title={title}
      onKeyDown={(e) => onClick && e.key === 'Enter' && onClick()}
      onClick={onClick}
      className={[`circle-arrow circle-arrow--${direction}`, disabled && 'circle-arrow--disabled', className].filter((i) => i).join(' ')}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        {direction === 'right' && <path d="M10.9998 8L5.9998 13L5.2998 12.3L9.5998 8L5.2998 3.7L5.9998 3L10.9998 8Z" fill="currentColor" />}
        {direction === 'left' && <path d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z" fill="currentColor" />}
      </svg>
    </i>
  );
}

export default CircleArrow;
