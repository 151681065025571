import React,{useState,CSSProperties, useCallback, useEffect} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Icon from "../../../../components/Icon"


export type Percentage={
    id:string,
    type:string,
    name:string,
    percentage:number,
    url?:string,
    items?:Percentage[]
}

type Props={
    id:string,
    list:Percentage[],
    type:string,
    onChange:(type:string,id:string)=>void,
    style?:CSSProperties,
    defaultOpenItem?:string
}
export default function PercentageDetail(props:Props){
    const {list,type,style,onChange,id,defaultOpenItem=""}=props;
    const [openItem,setOpenItem]=useState("")

    useEffect(()=>{
        setOpenItem(defaultOpenItem)
    },[defaultOpenItem])
    
    function handleItemClick(id:string,type:string){
        // if(id===openItem){
        //     setOpenItem("");
        //     return
        // }
        // setOpenItem(id);
        // onChange(type,id)
    }


    return <ol style={style} className={`learning-list-card_progress_${type}`}>
            {list.map((item:Percentage)=>{
                        
                        const hasChildren=item.items&&item.items.length>0;
                        return <li style={{}} key={item.name} className={`learning-list-card_progress_item learning-list-card_progress_item_${item.type} ${hasChildren?"hasChildren":""}`}>
                            <a href={item.url} target='_blank' className={`learning-list-card_progress_item_percentage_wrap`}>
                                <p><span className='hover_underline'>{item.name}</span></p>
                                {item.percentage>0&&<div className={`learning-list-card_progress_item_percentage`}>
                                    {item.percentage===100&&<Icon type="progress100"></Icon>}
                                    {item.percentage!==100&&<CircularProgressbar strokeWidth={15} styles={buildStyles({pathColor:"#6ABF4B"})} value={item.percentage}/>}
                                </div>}
                            </a>
                            {item.id===openItem&&<PercentageDetail key={item.id} id={item.id} onChange={onChange} style={{paddingLeft:20,marginTop:17}} list={item.items||[]} type={item.type}></PercentageDetail>}
                        </li>
            })}
    </ol>
}