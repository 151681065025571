import React,{useCallback, useEffect, useState} from "react"
import Icon from "../Icon"
import {useWindowWidth} from "../../utils/windowSize"
import "./index.scss"

type Props = {
    selectKey:string,
    onChange:(key:any)=>void,
    list:{key:string,name:string}[],
    tabSelectNums?:number
}


const SearchTab:React.FC<Props>=(props)=>{
    let { selectKey,onChange,list,children,tabSelectNums } = props;
    const [show,setShow]=useState(false)

    const windowSize=useWindowWidth()

    useEffect(()=>{
      
    },[])
    
    const onClick=useCallback((key:string)=>{
      onChange(key)
    },[])

    const handleCollapse=useCallback(()=>{
      setShow((show)=>!show)
    },[])


    const isMobile=windowSize<=768
    const showOther=!isMobile?true:show;
   
    const showSelectNums=tabSelectNums&&tabSelectNums>0
    return (
      <div className="learning_tab_wrap">
        <ul className="learning_tab">
          {list.map(_=>{
            return <li onClick={()=>{onClick(_.key)}} key={_.key} className={`learning_tab_item ${selectKey===_.key?'learning_tab_item_active':""}`}>{_.name}</li>
          })}
        </ul>
        {children&&<button onClick={handleCollapse} className={`learning_tab_btn ${showSelectNums?'learning_tab_btn_selected':''}`}><Icon color={showSelectNums?"#fff":"#0D274D"} type="collapse"></Icon>{showSelectNums?<span>{tabSelectNums}</span>:''}</button>}
        <div className={`learning_tab_other ${showOther?"learning_tab_other_show":""}`}>
          {children}
          {isMobile&&<button onClick={handleCollapse} className="learning_tab_other_btn">Close</button>}
        </div>
      </div>
    );
  };

export default SearchTab


