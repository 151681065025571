import React, { CSSProperties,useEffect } from "react"
import SearchInput from "../../SearchInput/SearchInput"
import mp4Url from "../../../../assets/video/LearningLabs.mp4"
import webmUrl from "../../../../assets/video/LearningLabs.webm"

import "./index.scss"

type SearchFilterBarProps = {
    label?: string,
    searchKey?: string,
    style?:CSSProperties
    onSearch:any
}


const SearchBanner:React.FC<SearchFilterBarProps>=(props)=>{
    let { label,searchKey,onSearch,style} = props;
  
    return (
      <div className={`search-banner`} style={style}>
         <video muted autoPlay loop playsInline className="banner_video" id="bg-video">
                    <source src={webmUrl} type="video/webm"/>
                    <source src={mp4Url} type="video/mp4"/>
         </video>
        <div className='container'>
          <div className={`search-banner_content`}>
            <h1 className="search-banner_content_title">Welcome to Learning Labs</h1>
            <p className="search-banner_content_desc">Learn about Cisco product suites, explore use cases, and develop your skills with interactive tutorials and built-in developer environments</p>
            <SearchInput label={label} searchKey={searchKey} onSearch={onSearch} />
          </div>
        </div>
        </div>
    );
  };

export default SearchBanner


