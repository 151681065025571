import React, { useCallback, useEffect, useState, ReactElement } from "react"
import Swiper from "../Swiper"
import Spinner from '../../components/Spinner';
import Card from "../Card"
import "./index.scss"
import { project } from "../../types"
import useGetNewContent from "../../requestHooks/useGetNewContent"
import Chevron from "../../components/Icon/Chevron"

type Props = {
  selectKey: string,
  onChange: (key: string) => void
}
type ContentProps = {
  type: string
}

export const list = [
  { key: 'AppDynamics,ThousandEyes', name: 'Full-Stack Observability' },
  { key: "latestLabs", name: 'Latest Learning Labs' },
  { key: 'latestModules', name: 'Latest Learning Modules' },
  { key: 'latestTracks', name: 'Latest Learning Tracks' }
]

export const DropdownTab: React.FC<Props> = (props) => {
  let { selectKey, onChange } = props;
  const [key, setKey] = useState(selectKey);
  const [show, setShow] = useState(false)

  useEffect(() => {
    setKey(selectKey)
  }, [selectKey])

  function onClick(key: string) {
    setKey(key);
    onChange(key);
    setShow(false)
  }

  const onBtnClick = useCallback(() => {
    setShow(_ => !_)
  }, [])

  const selectName = list.filter(_ => _.key === key)[0].name
  return (
    <div className="dropdown-tab">
      <button className="dropdown-tab_btn" onClick={onBtnClick}>{selectName}<Chevron size="20" color="#fff" direction={show ? 'up' : 'down'}></Chevron></button>
      <ul className="tab_list" style={{ display: show ? '' : 'none' }}>
        {list.map(_ => {
          return <li onClick={() => { onClick(_.key) }} key={_.key} className={`tab_item ${key === _.key ? 'tab_item_active' : ""}`}>{_.name}</li>
        })}
      </ul>
    </div>
  );
};


export const Tab: React.FC<Props> = (props) => {
  let { selectKey, onChange } = props;
  const [key, setKey] = useState(selectKey)

  useEffect(() => {
    setKey(selectKey)
  }, [selectKey])

  function onClick(key: string) {
    setKey(key);
    onChange(key)
  }
  return (
    <ul className="vertical_tab tab_list">
      {list.map(_ => {
        return <li onClick={() => { onClick(_.key) }} key={_.key} className={`tab_item ${key === _.key ? 'tab_item_active' : ""}`}>{_.name}</li>
      })}
    </ul>
  );
};



export function Content(props: ContentProps) {
  const { type } = props;
  const { data, isLoading, progress } = useGetNewContent(type)
  useGetNewContent("latestLabs")
  useGetNewContent("latestModules")
  useGetNewContent("latestTracks")

  return <div className='new-content_right'>
    {data && <Swiper refreshKey={type} slidesToShow={1} breakpoint={{ 821: { slidesToShow: 3 } }}>
      {data.map((item: project) => {
        const p = (progress[item.contentSubtype] || {})[item.code_name]
        return <Card progress={p} key={item.id} data={item}></Card>
      })}
    </Swiper>
    }
    {isLoading && <Spinner size="big" />}
  </div>
}


export default function NewContent() {
  const [tabKey, setTabKey] = useState(list[0].key)
  const onChange = useCallback((key) => {
    setTabKey(key)
  }, [])
  return (
    <div className='new-content_container' >
      <div className='new-content_wrap container'>
        <div className='new-content'>
          <div className="new-content_circleWrap">
            <div className="new-content_circle"></div>
          </div>
          <div className='new-content_left'>
            <div className='new-content_left_title'>What’s new</div>
            <Tab selectKey={tabKey} onChange={onChange}></Tab>
            <DropdownTab selectKey={tabKey} onChange={onChange}></DropdownTab>
          </div>
          <div className='new-content_rightWrap'>
            <Content type={tabKey}></Content>
          </div>
        </div>
      </div>
      <div className="new-content_block"></div>
    </div>
  );
};




