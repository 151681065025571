export const CATEGOTY_LIST=[
    {
        "value": "IoT",
        "label": "IoT"
    },
    {
        "value": "Cloud",
        "label": "Cloud"
    },
    {
        "value": "Networking",
        "label": "Networking"
    },
    {
        "value": "Data Center",
        "label": "Data Center"
    },
    {
        "value": "Security",
        "label": "Security"
    },
    {
        "value": "Analytics & Automation",
        "label": "Analytics & Automation"
    },
    {
        "value": "Collaboration",
        "label": "Collaboration"
    },
    {
        "value": "Mobility",
        "label": "Mobility"
    }
]

export const CATEGOTY_TITLE="All Categories"