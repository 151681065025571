import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import ErrorPage from './pages/Error/Error';
import LandingPage from './pages/LandingPage';
import Search from './pages/Search';
import MyLearning from './pages/MyLearning/MyLearning';
import Tracks from './pages/Tracks/Tracks';
import Modules from './pages/Modules/Modules';
import Lab from './pages/Lab/Lab';
import { RouteProps } from 'react-router';
import SubTypeSearch from './pages/Search/SubTypeSearch';
import {isLoginedIn} from "./utils/index"

const Root: React.FC<RouteProps> = (props) => {
    const loc = useLocation();
    const history = useHistory();

    useEffect(() => {
        window?.AnalyticsSDK?.trackPageView();
    }, [props.location]);

    // useEffect(() => {
    //     let { pathname, search } = loc;
    //     if (pathname && pathname.substring(pathname.length - 1) !== '/') {
    //         pathname = pathname + '/'
    //         history.push({
    //             pathname: pathname,
    //             search
    //         })
    //     }
    // }, [history, loc]);

    return (
        <Switch>
            <Route path="/404" component={ErrorPage} exact />
            <Route path="/mylearning" component={MyLearning} exact />

            <Route path="/search/tracks/*" component={SubTypeSearch}  />
            <Route path="/search/modules/*" component={SubTypeSearch} />
            <Route path="/search/labs/*" component={SubTypeSearch}  />
            {/* <Route path="/search/forYou/*" component={Search} /> */}
            <Route path="/search/" component={Search} exact />
        
            <Redirect from="/tracks" to="/search/tracks/" exact/>
            <Redirect from="/modules" to="/search/modules/" exact/>
            <Redirect from="/labs" to="/search/labs/" exact/>

            <Route path="/tracks/:trackCodeName" component={Tracks} exact />
            <Route path="/tracks/:trackCodeName/:moduleCodeName" component={Tracks} exact />
            <Route path="/tracks/:trackCodeName/*" component={Tracks} exact />
        
            <Route path="/modules/:moduleCodeName" component={Modules} exact />
            <Route path="/modules/:moduleCodeName/:labId" component={Modules} exact />
            <Route path="/modules/:moduleCodeName/:labId/*" component={Modules} exact />

            <Route path="/labs/:labId" component={Lab} exact />
            <Route path="/labs/:labId/step/:step_id" component={Lab} exact />
            <Route path="/labs/:labId/:step_url_id" component={Lab} exact />
            <Route path="/labs/:labId/*" component={Lab} exact />

            <Redirect from="/lab/:labId" to="/labs/:labId" />
            <Redirect from="/lab/:labId/*" to="/labs/:labId/*" />

            <Route path="/" render={()=>{
                const pathname=window.location.pathname;
                const reg=new RegExp(`^/${process.env.REACT_APP_BASENAME}/.+$`)
                if(reg.test(pathname)){
                    window.location.href=`/${process.env.REACT_APP_BASENAME}/`;
                    return null
                }
                if(isLoginedIn()) return <Search></Search>
                return <LandingPage></LandingPage>
            }}/>
            
            <Redirect from="*" to='/'/>
        </Switch>)
}
export default Root;