import { AxiosRequestConfig } from "axios"
import { request } from "./request"

export interface FetchResult<T> {
    data: T | null,
    error?: Error,
    success: boolean
}
export default function selfFetch<T>(url: string, cb: (data: FetchResult<T>) => void, config?: AxiosRequestConfig) {
    request(url!, config).then((result) => {
        const _data: T = result.data;
        cb({
            data: _data,
            success: true
        })
    }).catch(error => {
        cb({
            data: null,
            success: false,
            error
        })
    })
}