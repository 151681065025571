import React, { useCallback, useEffect, useState } from "react"
import "./WhatsNew.scss"
import { Tab, DropdownTab, Content, list } from "../../../../components/newContent"
import { useTrackVisibility } from 'react-intersection-observer-hook';

export default function WhatsNew() {
      const [ref, { isVisible }] = useTrackVisibility();

      const [tabKey, setTabKey] = useState(list[0].key)
      const onChange = useCallback((key) => {
            setTabKey(key)
      }, [])

      return (
            <div className='newContentContainer'>
                  <div className='newContentWrap container' ref={ref}>
                        <div className={`newContent ${isVisible ? 'fadeIn' : ''}`}>
                              <div className='whatsNewTitle'>What’s new</div>
                              <div className='whatsNewSubTitle'>Select a Learning Lab to get started</div>
                              <div className='newContentLeft'>
                                    <Tab selectKey={tabKey} onChange={onChange}></Tab>
                                    <DropdownTab selectKey={tabKey} onChange={onChange}></DropdownTab>
                              </div>
                              <div className='newContentRight'>
                                    <Content type={tabKey}></Content>
                              </div>
                        </div>
                  </div>
                  <div className="newContentBg"></div>
            </div>
      );
};