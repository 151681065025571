import React, { useState,useRef, useEffect, useCallback } from "react"
import Chevron from "../../../../components/Icon/Chevron"
import "./index.scss"

type Props={
    list:{value:string,name:string}[],
    value:string,
    title?:string,
    onChange:(value:string)=>void
}
export default function(props:Props){
    const {list,value,title,onChange}=props;
    const [show,setShow]=useState(false);
    const selectedRef=useRef(null)
    const selected=list.find(item=>item.value==value);

    function handleItemClick(val:string){
        setShow(false)
        onChange(val)
    }
    useEffect(()=>{
        function handleDocClick(e:MouseEvent){
            // @ts-ignore
            if(e.target!==selectedRef.current&&e.target.parentElement?.parentElement!==selectedRef.current){
                setShow(false)
            }
        }
        document.addEventListener('click',handleDocClick);
        return ()=>{
            document.removeEventListener('click',handleDocClick);
        }
    },[])

    const handleSelectedClick=useCallback((e:React.MouseEvent)=>{
        setShow(show=>!show)
    },[])

    return <div className="learning_select">
        <div ref={selectedRef} onClick={handleSelectedClick} className="learning_select_selected">
            {title?title+":":""}{selected?.name}<Chevron direction={show?'up':'down'}></Chevron>
        </div>
        <ul className={`learning_select_list ${show?'learning_select_list_show':''}`}>
            {list.map((item)=>{
                return <li className={`learning_select_list_item ${item.value===value?"learning_select_list_item_active":""}`} onClick={()=>handleItemClick(item.value)}>{item.name}</li>
            })}
        </ul>
    </div>
}