import React, { useCallback, useEffect, useState } from 'react';
import './search.scss';
import { useSearchQuery, useUrl } from './hooks';
import { SearchLayout } from './SearchLayout';
import { searchContext } from "./context"
import { SORT_BY_NAMES } from "./const"
import SearchBanner from "./components/Banner"

export default function SubTypeSearch() {
  let { params, paths, updateUrl } = useUrl();
  const {sortBy,keyword: searchKey} = params;
  const subtype = (params.contentType || paths[0].slice(0, -1)) as "module"|"track"|"lab";
  let contentType = params.contentType = subtype;

  let [{ dataList, pageCount, total }, isLoading, productsBuckets, languageBuckets] = useSearchQuery(params);

  const [cachedPageCount, setCachedPageCount] = useState(1)
  const [cachedTotal, setTotal] = useState(1)

  useEffect(() => {
    if (!isLoading && pageCount !== cachedPageCount) {
      setCachedPageCount(pageCount);
    }
  }, [dataList, isLoading]);

  
  useEffect(() => {
    if (!isLoading && total !== cachedTotal) {
      setTotal(total);
    }
  }, [dataList, isLoading]);

  const onChangePageNum = useCallback((e, page) => {
    updateUrl({ page: String(page) })
  }, []);

  const onSearch = useCallback(
    (key) => {
      updateUrl({ keyword: key, sortBy: key ? SORT_BY_NAMES.luceneScore : sortBy });
    },
    [updateUrl]
);

  const label = "Learning " + subtype[0].toUpperCase() + subtype.substr(1) + "s";
  

  return (
    <div>
      <SearchBanner style={{marginBottom:40}} searchKey={searchKey} onSearch={onSearch}></SearchBanner>
      <searchContext.Provider value={{ productsBuckets, languageBuckets }}>
        <SearchLayout label={label} {...{
          isLoading,
          dataList,
          pageCount:cachedPageCount,
          onChangePageNum,
          contentType,
          searchInputLabel: label,
          total:cachedTotal,
          categoriesMode: 'combine',
          productsBuckets
        }}>

        </SearchLayout >
      </searchContext.Provider>
    </div>

  )
}