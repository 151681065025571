import { useQuery } from "@tanstack/react-query"
import { api, request } from "../utils/request";
import { getProjectsByCodeNameList } from "./index"
import useGetProgressByCodeNameList from "./useGetProgressByCodeNameList"




export default function () {
  const { data, isLoading } = useQuery(['popular'], async function fetchPopular() {
    const res = await request.post(api.popular, {
      resource_type: ['lab_track', 'lab_module', 'lab_lab'],
      resource_number: [3, 3, 4],
      time_period: 'weekly'
    })
    // console.log('res', res)
    const popular = await getProjectsByCodeNameList(res.data.popular_content);
    popular.sort(() => .5 - Math.random())
    const recommend_id = res.data.recommend_id
    return { popular, recommend_id }

  }, { refetchOnWindowFocus: false });

  const progress = useGetProgressByCodeNameList(data?.popular || [])
  return { data, isLoading, progress }
}