import createRootReducer from './reducers/rootReducer';


import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';


export default function configureStore(initialState = {}, history: History) {
  const epicMiddleware = createEpicMiddleware();
  const composeEnhancers = composeWithDevTools({});
  const middlewares = [
    routerMiddleware(history),
    epicMiddleware
  ]
  const store = createStore(
    createRootReducer(connectRouter(history)),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  
  return store;
}
