export interface AppStateType {
    name: string,
    published: boolean,
    helloMsg: string,
    byeMsg: string
    helloImg: string | null
}

export interface RootState {
    app: AppStateType,
    router: any
}


export enum UpdateDataPaths {
    helloImg = 'helloImg',
    loadingHelloImg = '_state.isLoadingHelloImg',
    helloMsg = 'helloMsg',
    byeMsg = 'byeMsg',
    published = 'published',
}


export function toggleItemsInArray(sourceArr: any[] = [], inOrOutItems: any[] = []) {
    inOrOutItems.forEach(item => {
        let idx = sourceArr.indexOf(item);
        if (idx > -1) {
            sourceArr.splice(idx, 1);
        } else {
            sourceArr.push(item);
        }
    });
    return sourceArr;
}
export function mergeItemsToArray(sourceArr: any[] = [], inItems: any[] = []) {
    sourceArr = sourceArr.concat(inItems);
    return uniqueArr(sourceArr);
}
export function uniqueArr(arr: any[], prop?: string) {
    return arr.filter((v, idx, self) => (prop ? self.findIndex(item => item[prop] === v[prop]) : self.indexOf(v)) === idx);
}
export function isLoggedIn() {
    //@ts-ignore
    return window.PlatformSDK && window.PlatformSDK.isLoggedIn();
}