import React, { useEffect } from 'react'
import Search from "../Search"
import { BANNER_SUBTITLE0, BANNER_TITLE } from "../../const"
import bgVideo from "./videos/bgVideo.webm"
import ContentList from "../ContentList"
import { useTrackVisibility } from 'react-intersection-observer-hook';

export default function Banner() {
    const [ref, { isVisible }] = useTrackVisibility();
    useEffect(() => {
        const bgvid: HTMLVideoElement | null = document.querySelector("#bgvid");
        if (bgvid) {
            bgvid.playbackRate = 0.3
        }
    })


    return <div className='topMain'>
        <video muted={true} playsInline loop className="bgvid" id="bgvid" autoPlay>
            <source src={bgVideo} type="video/webm"></source>
        </video>
        <div className="topWrap" ref={ref}>
            <h1 className='topTitle1'>
                {BANNER_TITLE}
                <span className='topTitle2'>Learning Labs</span>
            </h1>
            <div className="subtitle0">{BANNER_SUBTITLE0}</div>
            <Search></Search>
            <ContentList isVisible={isVisible}></ContentList>
        </div>
    </div>
}