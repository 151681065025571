import React from "react"
import "./index.scss"
import Icon from "../../../../components/Icon"
import {noContentWordsMap,PROGRESS_COMPLETED,PROGRESS_IN_PROGRESS} from "../../const"
import { Link } from 'react-router-dom';

type Props={projectType:typeof PROGRESS_COMPLETED|typeof PROGRESS_IN_PROGRESS}
export function NoContent(props:Props){
    const {projectType}=props;
    return <div className="blank_wrap no-content">
        <div className="blank_wrap_img no-content_img"></div>
        <div className="blank_wrap_content">
            <p>{noContentWordsMap[projectType]}</p>
            <Link to={"/search/"} className="no-content_explore">Explore library<Icon type="internal-link"></Icon></Link>
        </div>

    </div>
}

export function NoLogin(){
    const login = () => {
        //@ts-ignore
        window.PlatformSDK.logout();
        //@ts-ignore
        window.PlatformSDK.openLogin();
    };
    return <div className="blank_wrap no-login">
        <div className="blank_wrap_img no-login_img"></div>
        <div className="blank_wrap_content">
            <p>Please login to see your learning history and personalized recommendations! Start your learning journey now !</p>
            <div className="no-login_btns"><button onClick={login} className="no-login_login">Log in<Icon type="internal-link"></Icon></button><span>Not a member？</span><div onClick={login} className="no-login_sign-up">Sign up here</div></div>
        </div>
    </div>
}