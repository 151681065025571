import React from 'react'
import './index.scss';

export { default as CircleArrow } from './CircleArrow';
export { default as Chevron } from './Chevron';
export { default as DevEnv } from './DevEnv';

type Props = {
  type: string;
  disabled?: boolean;
  className?: string;
  title?: string;
  color?: string;
  onClick?: () => void;
  size?: number;
}

function Icon({
  title = undefined,
  type = '',
  disabled = false,
  className = '',
  color = undefined,
  onClick = undefined,
  size = 16,
}: Props): JSX.Element {
  return (
    <i aria-hidden={title ? undefined : true}
      role="button"
      tabIndex={onClick ? 0 : -1}
      onClick={onClick && (() => onClick())}
      onKeyDown={onClick && ((e) => e.key === 'Enter' && onClick())}
      style={{ color, height: size, width: size }}
      title={title}
      className={[`icon icon--${type}`, disabled && 'icon--disabled', className].filter((i) => i).join(' ')}
    >
      {type === 'check-circle' && (
        <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20" fill="#47CB63" />
          <path fillRule="evenodd" clipRule="evenodd" d="M32.2483 15.17L29.5968 12.5183L18.1062 24.0089L11.2757 17.1783L8.62402 19.83L15.4546 26.6604L18.1062 29.3121L20.7579 26.6604L32.2483 15.17Z" fill="white" />
        </svg>
      )}
      {type === 'check' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 12L2 7.49997L2.707 6.79297L6.5 10.5855L13.293 3.79297L14 4.49997L6.5 12Z" fill="currentColor" />
        </svg>
      )}
      {type === 'close-fill' && (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1C4.1 1 1 4.1 1 8C1 11.9 4.1 15 8 15C11.9 15 15 11.9 15 8C15 4.1 11.9 1 8 1ZM10.7 11.5L8 8.8L5.3 11.5L4.5 10.7L7.2 8L4.5 5.3L5.3 4.5L8 7.2L10.7 4.5L11.5 5.3L8.8 8L11.5 10.7L10.7 11.5Z" fill="currentColor" />
        </svg>
      )}
      {type === 'external-link' && (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M5 3V4H11.295L3 12.295L3.705 13L12 4.705V11H13V3H5Z" fill="currentColor" />
        </svg>
      )}
      {type === 'internal-link' && (
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
          <path d="M7.875 2.625L7.24937 3.23444L10.5656 6.5625H1.75V7.4375H10.5656L7.24937 10.7507L7.875 11.375L12.25 7L7.875 2.625Z" fill="currentColor" />
        </svg>
      )}
      {type === 'list' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 4H8V5H15V4Z" fill="currentColor" />
          <path d="M15 11H8V12H15V11Z" fill="currentColor" />
          <path d="M5 7H2C1.73488 6.9997 1.4807 6.89424 1.29323 6.70677C1.10576 6.5193 1.0003 6.26512 1 6V3C1.0003 2.73488 1.10576 2.4807 1.29323 2.29323C1.4807 2.10576 1.73488 2.0003 2 2H5C5.26512 2.0003 5.5193 2.10576 5.70677 2.29323C5.89424 2.4807 5.9997 2.73488 6 3V6C5.9997 6.26512 5.89424 6.5193 5.70677 6.70677C5.5193 6.89424 5.26512 6.9997 5 7ZM2 3V6H5.0006L5 3H2Z" fill="currentColor" />
          <path d="M5 14H2C1.73488 13.9997 1.4807 13.8942 1.29323 13.7068C1.10576 13.5193 1.0003 13.2651 1 13V10C1.0003 9.73488 1.10576 9.4807 1.29323 9.29323C1.4807 9.10576 1.73488 9.0003 2 9H5C5.26512 9.0003 5.5193 9.10576 5.70677 9.29323C5.89424 9.4807 5.9997 9.73488 6 10V13C5.9997 13.2651 5.89424 13.5193 5.70677 13.7068C5.5193 13.8942 5.26512 13.9997 5 14ZM2 10V13H5.0006L5 10H2Z" fill="currentColor" />
        </svg>
      )}
      {type === 'grid' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11H4C4.26522 11 4.51957 11.1054 4.70711 11.2929C4.89464 11.4804 5 11.7348 5 12V14C5 14.2652 4.89464 14.5196 4.70711 14.7071C4.51957 14.8946 4.26522 15 4 15ZM2 12V14H4V12H2Z" fill="currentColor" />
          <path d="M9 15H7C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14V12C6 11.7348 6.10536 11.4804 6.29289 11.2929C6.48043 11.1054 6.73478 11 7 11H9C9.26522 11 9.51957 11.1054 9.70711 11.2929C9.89464 11.4804 10 11.7348 10 12V14C10 14.2652 9.89464 14.5196 9.70711 14.7071C9.51957 14.8946 9.26522 15 9 15ZM7 12V14H9V12H7Z" fill="currentColor" />
          <path d="M14 15H12C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1054 14.5196 11 14.2652 11 14V12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11H14C14.2652 11 14.5196 11.1054 14.7071 11.2929C14.8946 11.4804 15 11.7348 15 12V14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15ZM12 12V14H14V12H12Z" fill="currentColor" />
          <path d="M4 10H2C1.73478 10 1.48043 9.89464 1.29289 9.70711C1.10536 9.51957 1 9.26522 1 9V7C1 6.73478 1.10536 6.48043 1.29289 6.29289C1.48043 6.10536 1.73478 6 2 6H4C4.26522 6 4.51957 6.10536 4.70711 6.29289C4.89464 6.48043 5 6.73478 5 7V9C5 9.26522 4.89464 9.51957 4.70711 9.70711C4.51957 9.89464 4.26522 10 4 10ZM2 7V9H4V7H2Z" fill="currentColor" />
          <path d="M9 10H7C6.73478 10 6.48043 9.89464 6.29289 9.70711C6.10536 9.51957 6 9.26522 6 9V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H9C9.26522 6 9.51957 6.10536 9.70711 6.29289C9.89464 6.48043 10 6.73478 10 7V9C10 9.26522 9.89464 9.51957 9.70711 9.70711C9.51957 9.89464 9.26522 10 9 10ZM7 7V9H9V7H7Z" fill="currentColor" />
          <path d="M14 10H12C11.7348 10 11.4804 9.89464 11.2929 9.70711C11.1054 9.51957 11 9.26522 11 9V7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6H14C14.2652 6 14.5196 6.10536 14.7071 6.29289C14.8946 6.48043 15 6.73478 15 7V9C15 9.26522 14.8946 9.51957 14.7071 9.70711C14.5196 9.89464 14.2652 10 14 10ZM12 7V9H14V7H12Z" fill="currentColor" />
          <path d="M4 5H2C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H4C4.26522 1 4.51957 1.10536 4.70711 1.29289C4.89464 1.48043 5 1.73478 5 2V4C5 4.26522 4.89464 4.51957 4.70711 4.70711C4.51957 4.89464 4.26522 5 4 5ZM2 2V4H4V2H2Z" fill="currentColor" />
          <path d="M9 5H7C6.73478 5 6.48043 4.89464 6.29289 4.70711C6.10536 4.51957 6 4.26522 6 4V2C6 1.73478 6.10536 1.48043 6.29289 1.29289C6.48043 1.10536 6.73478 1 7 1H9C9.26522 1 9.51957 1.10536 9.70711 1.29289C9.89464 1.48043 10 1.73478 10 2V4C10 4.26522 9.89464 4.51957 9.70711 4.70711C9.51957 4.89464 9.26522 5 9 5ZM7 2V4H9V2H7Z" fill="currentColor" />
          <path d="M14 5H12C11.7348 5 11.4804 4.89464 11.2929 4.70711C11.1054 4.51957 11 4.26522 11 4V2C11 1.73478 11.1054 1.48043 11.2929 1.29289C11.4804 1.10536 11.7348 1 12 1H14C14.2652 1 14.5196 1.10536 14.7071 1.29289C14.8946 1.48043 15 1.73478 15 2V4C15 4.26522 14.8946 4.51957 14.7071 4.70711C14.5196 4.89464 14.2652 5 14 5ZM12 2V4H14V2H12Z" fill="currentColor" />
        </svg>
      )}
      {
        type === 'progress100' && (
          <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Checkmark--filled">
                  <path id="Vector" d="M8 0.999512C6.61553 0.999512 5.26216 1.41005 4.11101 2.17922C2.95987 2.94839 2.06266 4.04165 1.53285 5.32073C1.00303 6.59981 0.86441 8.00728 1.13451 9.36514C1.4046 10.723 2.07129 11.9703 3.05026 12.9493C4.02922 13.9282 5.2765 14.5949 6.63437 14.865C7.99224 15.1351 9.3997 14.9965 10.6788 14.4667C11.9579 13.9369 13.0511 13.0396 13.8203 11.8885C14.5895 10.7374 15 9.38398 15 7.99951C15 6.143 14.2625 4.36252 12.9497 3.04976C11.637 1.73701 9.85652 0.999512 8 0.999512ZM7 10.7949L4.5 8.29491L5.2953 7.49951L7 9.20411L10.705 5.49951L11.5029 6.29246L7 10.7949Z" fill="#6ABF4B"/>
              </g>
          </svg>
        )
      }
      {type === 'collapse' && <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.125 0.625H0.875V1.85H13.125V0.625Z" fill={color}></path>
        <path d="M10.675 4.3H3.325V5.525H10.675V4.3Z" fill={color} ></path>
        <path d="M9.45 7.975H5.1625V9.2H9.45V7.975Z" fill={color} ></path>
      </svg>}
    </i>
  );
}

export default Icon;
