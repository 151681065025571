import React from 'react'
import { Link } from 'react-router-dom';
import "./list.scss"
const trackTips = [
  // 'A collection of interactive tutorials',
  // 'For product suites and related concepts',
  'Complete in days',
  'Consecutive Learning Modules',
]
const moduleTips = [
  // 'A group of interactive tutorials ',
  // 'For related use cases and skills',
  'Complete in hours',
  'Consecutive Learning Labs',

]
let labTips = [
  // 'Single interactive tutorial ',
  // 'For specific use case or skill',
  'Complete in less than an hour',
  '58+ Labs to code in the browser',

]
let jupyterTip = 'Interactive labs with Jupyter'
// labTips.push(jupyterTip)


function UlList(props: any) {
  let { tips } = props
  return <ul className='listCircle'>
    {
      tips.map((tip: string) => {
        if (tip.indexOf(jupyterTip) != -1) {
          return <li key={tip} className='jupyterTip'>
            {tip}
            <div className="jupyterTipNewIcon"></div>
          </li>
        } else {
          return <li key={tip}>{tip}</li>
        }
      }
      )
    }
  </ul>
}

interface contentProps {
  isVisible: boolean
}
export default function ContentList(props:contentProps) {
  const { isVisible } = props
  return <div className={`topList ${isVisible ? 'fadeIn' : ''}`}>
    <div className='topItem'>
      <div className='topItemLeft'></div>
      <div className='topItemRight'>
        <div className='topItemTitle'>Learning Tracks</div>
        <div className='topItemDes'>
          {/* A guided learning experience that consists of a series of modules or standalone codelabs, and quizzes. */}
          <UlList tips={trackTips}></UlList>

        </div>
        <Link to="/search/?contentType=track&page=1">
          <div className='topItemBtn hoverUnderLine'>Explore Learning Tracks <div className="topItemBtnIcon"></div> </div>
        </Link>
      </div>
    </div>
    <div className='topItem'>
      <div className='topItemLeft topItemLeft2'></div>
      <div className='topItemRight'>
        <div className='topItemTitle'>Learning Modules  </div>
        <div className='topItemDes'>
          {/* Standalone learning units organized by selected topics. consisting of articles, videos, quizzes, and codelabs. Multiple modules can be part of a learning track. */}
          <UlList tips={moduleTips}></UlList>
        </div>
        <Link to="/search/?contentType=module&page=1">
          <div className='topItemBtn hoverUnderLine'>Explore Learning Modules <div className="topItemBtnIcon"></div> </div>
        </Link>
      </div>
    </div>
    <div className='topItem'>
      <div className='topItemLeft topItemLeft3'>
        {/* <div className="topItemJupyter"></div> */}
      </div>
      <div className='topItemRight'>
        <div className='topItemTitle'>Learning Labs</div>
        <div className='topItemDes'>
          {/* Articles, videos and learning content with hands-on learning environment experience that can be part of a module. */}
          <UlList tips={labTips}></UlList>
        </div>
        <Link to="/search/?contentType=lab&page=1">
          <div className='topItemBtn hoverUnderLine'>Explore Learning Labs<div className="topItemBtnIcon"></div></div>
        </Link>
      </div>
    </div>
  </div>
}