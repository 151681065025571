import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { CATEGOTY_LIST, CATEGOTY_TITLE } from "./const"
import { ContainerTypes } from "./interface"
import ProductTags from "../ProductTags"
import LanguageTags from "../LanguageTags"
import { Category as TechTagsCategory } from "../TechTags";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { request, api } from '../../../../utils/request';
import useSWR from "swr";

import { ContainerTypes as CategoriesTypes } from "./interface"

import "./components.scss"

export function Language(props: { selected: string[], onChange: ContainerTypes['onChange'] }) {
    const { selected, onChange } = props;
    const [open, setOpen] = useState(true);

    const _selected = selected.filter(_ => _)
    function clear() {
        onChange({ language: '' })
    }

    function handleOpen() {
        setOpen(!open)
    }

    const onTagClicked = useCallback(
        function onTagClicked(tag: TechTagsCategory, selectedTags: TechTagsCategory[]) {
            const data = selectedTags.map((tag) => tag.value).join(",");
            onChange({ language: data })
        }, [onChange]
    )

    return <div className={`category-products ${!open ? 'category-products_close' : ''}`}>
        {/* <div className="category-products_title">
            <h4 onClick={handleOpen}><ArrowDropDownIcon/>Language</h4>{_selected.length>0?<span className="category_word" onClick={clear}>Clear</span>:''}
        </div> */}
        {/* <div style={{display:!open?'none':''}}><LanguageTags selectedTagStrArr={selected} onTagClicked={onTagClicked}></LanguageTags></div> */}
    </div>
}

export function Products(props: { selected: string[], onChange: ContainerTypes['onChange'] }) {
    const { selected, onChange } = props;
    const [open, setOpen] = useState(true);

    const _selected = selected.filter(_ => _)
    function clear() {
        onChange({ products: '' })
    }

    function handleOpen() {
        setOpen(!open)
    }

    const onTagClicked = useCallback(
        function onTagClicked(tag: TechTagsCategory, selectedTags: TechTagsCategory[]) {
            const data = selectedTags.map((tag) => tag.value).join(",");
            onChange({ products: data })
        }, [onChange]
    )

    return <div className={`category-products ${!open ? 'category-products_close' : ''}`}>
        <div className="category-products_title">
            <h4 onClick={handleOpen}><ArrowDropDownIcon />Cisco Product</h4>{_selected.length > 0 ? <span className="category_word" onClick={clear}>Clear</span> : ''}
        </div>
        <div style={{ display: !open ? 'none' : '' }}><ProductTags selectedTagStrArr={selected} onTagClicked={onTagClicked}></ProductTags></div>
    </div>
}

export function Category(props: { data: string, onClick: ContainerTypes['onChange'] }) {
    const { data, onClick } = props;
    function clear() {
        onClick({ categories: '', products: '' })
    }
    return <div className="category-tag category-box">
        <h5 className="category-tag_title tag_left-arrow category_word" onClick={clear}>{CATEGOTY_TITLE}</h5>
        <p className="category-tag_content">{data}</p>
    </div>
}

export const getFilter = (url: string) => {
    return request(url).then((res) => {
        if (res.data?.items?.length) {
            let allItems = res.data.items;
            //@ts-ignore
            let allCategories = allItems.map((item) => {
                return {
                    label: item.name,
                    value: item.name,
                }
            })
            // console.log("allCategories:", allCategories)
            return allCategories
        }
        return []
    })
}

export function Categories(props: { selected: string[], onChange: ContainerTypes['onChange'] }) {
    const { selected, onChange } = props;
    function handleClick(data: string, active: boolean) {
        if (active) {
            onChange({ categories: '' })
        } else {
            onChange({ categories: data })
        }
    }
    // preload(api.tags, getFilter)
    //@ts-ignore
    const { data: allCategory,isLoading } = useSWR(api.tags, getFilter)
    // console.log("allCategory:", allCategory,isLoading)

    return <div className="category-tags">
        <h5 className="category-tags_title">{CATEGOTY_TITLE}</h5>
        <ul className="category-tags_list">
            {
                //@ts-ignore
                allCategory ? allCategory.map((data) => {
                    const active = selected.includes(data.value);
                    return <li key={data.value} onClick={() => { handleClick(data.value, active) }} className={`category-tags_list_item tag_right-arrow ${active ? 'category-tags_list_item_active' : ""}`}>
                        {data.label}
                    </li>
                }) : ''
            }
        </ul>
    </div>
}

export function ClearBar(props: { onClear: () => void, hiddenBtn: boolean }) {
    const { onClear, hiddenBtn } = props;
    return <div className="category-tags_clear-bar">
        <h4 className="category-tags_clear-bar_title">Refine Search Results</h4>
        {/* {!hiddenBtn&&<span className="category-tags_clear-bar_action category_word" onClick={onClear}>Clear all</span>} */}
    </div>
}

type MobileBarType = {
    isSearchPage: boolean,
    category?: string,
    onCategoriedChange: CategoriesTypes['onChange'],
    selectedProducts: string[],
    openPanel: (show?:boolean) => void
}

export function MobileBar(props: MobileBarType) {
    const { category, onCategoriedChange, openPanel, isSearchPage, selectedProducts} = props;
    const [showCategories, setShowCategories] = useState(false)


    function toggleShowCategories() {
        openPanel(false)
        setShowCategories(!showCategories)
    }

    const handleCategoriesChange: ContainerTypes['onChange'] = function (data) {
        toggleShowCategories();
        onCategoriedChange(data)
    }

    function handleClearCategories() {
        if (isSearchPage) {
            onCategoriedChange({ categories: '' })
            return
        }
        onCategoriedChange({ categories: '', products: '' })
    }

    function handleOpenPanel() {
        openPanel()
        setShowCategories(false)
    }

    const filterLen = selectedProducts.length > 0 ? `(${selectedProducts.length})` : ''

    return <div className="category-tags_mobile-bar">
        {!category && <div className="mobile-bar_wrap">
            <h3 onClick={toggleShowCategories} className="category-tags_mobile-bar_title tag_right-arrow">All Categories</h3>
            {isSearchPage && <button className="category-tags_mobile-bar_btn" onClick={handleOpenPanel}>Filter{filterLen}</button>}
        </div>}
        {category && <div>
            <div className="mobile-bar_wrap">
                <h3 onClick={handleClearCategories} className="category-tags_mobile-bar_title tag_left-arrow">All Categories</h3>
                <button className="category-tags_mobile-bar_btn" onClick={()=>openPanel()}>Filter{filterLen}</button>
            </div>
            <p className="category-tags_mobile-bar_category">{category}</p>
        </div>}
        <div className={`mobile-categories-wrap ${showCategories?'mobile-categories-wrap_show':""}`}><div>
            <Categories selected={[]} onChange={handleCategoriesChange}></Categories>
        </div></div>
    </div>
}