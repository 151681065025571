import React, { useCallback, useState } from 'react';
import { SearchType } from '../hooks';

type TabsProps = {
    tabs: SearchType[],
    selectedId?: string,
    onTabSelected?: Function
}
const Tabs: React.FC<TabsProps> = (props) => {
    let {
        tabs,
        selectedId = props.tabs[0].id,
        onTabSelected
    } = props;

    const _selectTab = useCallback((tab) => {
        onTabSelected && onTabSelected(tab);
    }, [onTabSelected]);
    return (
        <div className="tabWrap">
            <div className="tab-items">
                {tabs.map(tab => {
                    return (
                        <div key={tab.id} className={`${selectedId === tab.id ? 'selected-tab ' : ''}tab-item`} onClick={_selectTab.bind(null, tab)}>
                            <div className="tab-item-content">
                                {tab.label + (tab.total ===undefined ? " (...)" : ` (${tab.total})`)}
                            </div>
                        </div>)
                })
                }
            </div>
        </div>
    )
}
export default Tabs;