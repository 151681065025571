import React, { useCallback } from 'react'
import { NewMenu } from "../../components"
import Nav from "../../components/Nav"
import Banner from "./components/Banner"
import PopularContent from "../../components/PopularContent"
import RunCode from "./components/RunCode/RunCode"
import WhatsNew from "./components/WhatsNew/WhatsNew"
import { GetStarted } from "./const"
import { Link, useHistory } from "react-router-dom";
import { canGoToForYou } from "../../utils"
import { useTrackVisibility} from 'react-intersection-observer-hook';

import './LandingPage.scss'
import './index.scss'

export default function Index() {
    const { push } = useHistory()
    const clickGetStart = useCallback(() => {
        if (canGoToForYou()) {
            push("/")
        }
    }, [])
    const [ref, { isVisible }] = useTrackVisibility({threshold:.8});
    const [popularContent, popularContentIns] = useTrackVisibility();

    

    return <div className='landingPageRoot SwiperHeight'>
        <NewMenu label=""></NewMenu>
        <Banner></Banner>
        <div className="getStarted">
            <div className="getStartedTitle">{GetStarted.Title}</div>
            <div className="getStartedWrap ">
                <div className="getStartedLeft">
                    <div className="getStartedP1">{GetStarted.p1}</div>
                    <div className="getStartedP2">{GetStarted.p2}</div>
                    <div className="getStartedBtnWrap">

                        <div onClick={clickGetStart} className="blueBtn">Get started <div className="iconArrow"></div> </div>

                        <Link to={"/search/?contentType=track,module,lab"}>
                            <div className="getStartedExplore">Explore Learning Library<div className="iconArrowBlue"></div> </div>
                        </Link>
                    </div>
                </div>
                <div className="getStartedRight" ref={ref}>
                    <div className={`getStartedRightLeft get-start ${isVisible ? '' : 'get-start_hidden'}`}>
                        <div style={{transitionDelay:'.6s',animationDelay: '1.2s'}} className={`getStartedJupyter ${isVisible ? 'get-start_float get-start_big' : 'get-start_small'}`}></div>
                    </div>
                    <div className={`getStartedRightWrap`}>
                        <div style={{transitionDelay:'.2s'}} className={`getStartedRightRight get-start ${isVisible ? '' : 'get-start_hidden'}`}></div>
                        <div className={`getStartedRightCircle`} ></div>
                        <div style={{transitionDelay:'0.8s',animationDelay: '3.1s'}} className={`getStartedRightIcon ${isVisible ? 'get-start_float get-start_big' : 'get-start_small'}`} ></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="popularContentWrap blue-bg">
            <div className="popularTitle">Trending Learning Labs in our community</div>
            <div ref={popularContent} className={`popularWrap ${popularContentIns.isVisible ? 'fadeIn' : ''}`}>
                <PopularContent></PopularContent>
            </div>
        </div>
        <RunCode></RunCode>
        <WhatsNew></WhatsNew>
        <Nav></Nav>
    </div>
}