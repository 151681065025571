import React from "react"
let timer=false;
const TOP=245;
export function searchPageScrollToTop(){
    if(!timer){
        timer=true;
        const scrollY=window.scrollY;
        if(scrollY>TOP){
            window.scrollTo(0,TOP)
        }
        timer=false
    }
}

function firstToUpper(str:string){
    return str.replace(/\b(\w)(\w*)/g,function($0,$1,$2){
        return $1.toUpperCase()+$2
    })
}

function getTypeTitle(data:string,isDne?:boolean){
    if(data.split(",").length>1){
        return 'results'
    }
    let prevWord=`Learning ${firstToUpper(data)}s`
    if(isDne){
        prevWord="Workshops"
    }
    return prevWord;
}

function getSearchKey(searchKey:string){
    return searchKey?<span className="count-info_keyword">{`"${searchKey}"`}</span>:''
}

function getCategoryWord(category?:string){
    if(category){
        return `in ${category}`
    }
    return ''
}

type CountInfoTitle={
    searchKey?:string,
    contentType:string,
    category?:string,
    isDneOnly?:boolean,
    total:number
}
export function getCountInfoTitle(props:CountInfoTitle){
    const {searchKey='',contentType,category,total,isDneOnly}=props;
    return <span>All {getSearchKey(searchKey)}{` ${getTypeTitle(contentType,isDneOnly)} ${getCategoryWord(category)} (${total}) `}</span>
}