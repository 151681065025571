import {useLayoutEffect,useState} from "react"
import {isLoginedIn} from "../utils"

const ID='platform-nps-sdk';
const URL='https://static.testing.devnetcloud.com/form/sdk.js'
const PROD_URL="https://static.production.devnetcloud.com/form/sdk.js"
const PRODUCTION_ENV="production"

export default function(env:string){
    const [installed,setInstalled]=useState(false)
    useLayoutEffect(()=>{
        if(!isLoginedIn()){return}
        if (document.getElementById(ID) == null) {
            let beacon = document.createElement('script');
            beacon.type = 'text/javascript';
            beacon.id = ID;
            beacon.async = true;
            beacon.src = env===PRODUCTION_ENV?PROD_URL:URL;
            document.body.appendChild(beacon);
            beacon.onload=function() {
                setInstalled(true)
            }
        }
    },[env])
    return installed
}