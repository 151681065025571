import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from 'react-router-dom';
import { useLocation} from "react-router-dom";
import queryString from "query-string";
import "./search.scss";
import { QueryResult, useUrl } from "./hooks";
import ResultList from "./ResultList";
import {
  Category as TechTagCategory,
} from "./Tags/TechTags";

import Categories from "./Tags/categories";
import { ContainerTypes as CategoriesTypes } from "./Tags/categories/interface"
import SearchFilterBar from "./components/SearchFilterBar"
import SearchFilterContent from "./components/SearchFilterContent"

import { searchPageScrollToTop, getCountInfoTitle } from "./utils"
import { productsBucketsType } from "./interface"

export type SearchLayoutProps = {
  label: string;
  isLoading: boolean;
  dataList: QueryResult["dataList"];
  pageCount: number;
  total: number;
  onChangePageNum?: (e: any, page: number) => void;
  contentType: string;
  searchInputLabel?: string;
  selectedTags?: TechTagCategory[];
  categoriesMode: CategoriesTypes['mode'];
  productsBuckets: productsBucketsType
};




export const SearchLayout: React.FC<SearchLayoutProps> = (props) => {
  let { params, updateUrl, paths } = useUrl();
  const loc = useLocation();
  let { pathname, search } = loc,
  searchObj = queryString.parse(search)

  
  let {
    label,
    isLoading,
    dataList = [],
    total,
    pageCount,
    contentType,
    categoriesMode,
    productsBuckets
  } = props;


  let {
    keyword: searchKey,
    complexity,
    products,
    language,
    categories,
    page = 1,
    pageType,
    isDneOnly,
    sortBy,
    isDevEnvOnly
  } = params;
  

  const [allSelectedTagStr, setAllSelectedTagStr] = useState<string>("");
  useEffect(() => {
    const allTags = [
      ...(categories || "").split(","),
      ...(products || "").split(","),
      ...[complexity || ""],
    ];

    const currentAllTags = allSelectedTagStr.split(",");

    const _allTags = allTags
      .filter((i) => i !== "")
      .sort((a, b) => {
        let aIdx = currentAllTags.indexOf(a);
        aIdx = aIdx === -1 ? 9999 : aIdx;
        let bIdx = currentAllTags.indexOf(b);
        bIdx = bIdx === -1 ? 9999 : bIdx;

        return aIdx > bIdx ? 1 : -1;
      });

    setAllSelectedTagStr(_allTags.join(","));
  }, [complexity, products, categories]);
  


  const onCategoriedChange: CategoriesTypes["onChange"] = useCallback(
    (data) => {
      updateUrl(data);
    }, [updateUrl]);



  const categoriesSelected = useMemo(() => params.categories ? params.categories.split(",").filter(_ => _) : [], [params.categories])
  const productsSelected = useMemo(() => (products || '').split(',').filter(_ => _), [products])
  const languageSelected = useMemo(() => (language || '').split(',').filter(_ => _), [language])

  const [showFilterPanel, setShowFilterPanel] = useState<undefined | boolean>(undefined);

  let onFiltersBtnClicked = useCallback((show?:boolean) => {
    if(show!==undefined){
      setShowFilterPanel(show)
      return
    }
    if(!showFilterPanel){
      window.scrollTo(0,512)
    }
    setShowFilterPanel(!showFilterPanel)
  }, [showFilterPanel]);

  //@ts-ignore
  const pageUrl = useCallback((pageNum: number) => {
    //@ts-ignore
    searchObj.page = pageNum
    //fixed next arrow href to over pageCount url when selected lasted page
    if (pageNum > pageCount) {
      return '#'
    }

    let queryStr = queryString.stringify(searchObj, { encode: false })
    return pathname + '?' + queryStr
  }, [pathname, search, pageCount])



  const onPageNumChange = useCallback(function onPageNumChange() {
    searchPageScrollToTop()
  }, [])

  const countInfo = getCountInfoTitle({ searchKey, category: categories, total, contentType, isDneOnly: isDneOnly === "true" ? true : false })


  return (
    <React.Fragment>
      <div className={`search-root`}>
       <div className={`main show-left-${showFilterPanel} container`}>
          <div className="left">
            <div>
              <Categories productsBuckets={productsBuckets} onChange={onCategoriedChange} selected={categoriesSelected} selectedProducts={productsSelected} languageSelected={languageSelected} mode={categoriesMode} ></Categories>
            </div>
          </div>

          <div className="right">
            <div className="search-filter">
              <SearchFilterBar isLoading={isLoading} searchKey={searchKey || ''} total={total}
                showTotal={paths[0] !== 'search'}
                selectedTags={categoriesSelected}
                selectedProducts={productsSelected}
                onFiltersBtnClicked={onFiltersBtnClicked}
                isFilterPanelShow={!!showFilterPanel}
                listLength={dataList.length}
                contentType={contentType}
                category={categories}
                title={countInfo}
                onCategoriedChange={onCategoriedChange}
                isSearchPage={!!props.children}
              >
                {props.children}
              </SearchFilterBar>
              <div className="search-filter-content-wrap">
                <SearchFilterContent contentType={contentType} sortBy={sortBy} isDevEnvOnly={isDevEnvOnly} onChange={onCategoriedChange}>
                  <div style={{ minHeight: 32 }}>
                    {pageCount > 1 ? (
                      <Pagination
                        count={pageCount}
                        page={Number(page) || 1}
                        // onChange={onChangePageNum}
                        onChange={onPageNumChange}
                        variant="outlined"
                        shape="rounded"
                        className="pagination"
                        renderItem={(item) => (
                          <PaginationItem
                            component={Link}
                            //@ts-ignore
                            to={() => pageUrl(item.page)}
                            {...item}
                          />
                        )}
                      />
                    ) : null}
                  </div>
                </SearchFilterContent>
              </div>
            </div>
            <div className="rightWrap">
              <ResultList
                isLoading={isLoading}
                dataList={dataList}
                contentType={contentType}
              />
              <div className="mobile-pagination">{pageCount > 1 && !isLoading ? (
                <Pagination
                  count={pageCount}
                  page={Number(page) || 1}
                  // onChange={onChangePageNum}
                  onChange={onPageNumChange}
                  variant="outlined"
                  // shape="rounded"
                  className="pagination"
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      //@ts-ignore
                      to={() => pageUrl(item.page)}
                      {...item}
                    />
                  )}
                />
              ) : null}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
