import React, { useEffect, useState } from 'react';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import configureStore from './store';
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import useRouter from './utils/useRouter';
import ScrollToTop, { toTop } from './components/ScrollToTop/ScrollToTop';
import LearningCenterNPS from "./components/LearningCenterNPS"
import "./common.scss"
import ReportBug from './pages/ReportBug/ReportBugDialog';
import { setCanonicalLink } from './utils/index'


const history = createBrowserHistory({
    basename: '/' + process.env.REACT_APP_BASENAME + '/'
});
const store = configureStore({}, history);
const queryClient = new QueryClient()

type MainProps = {
    comp: React.ComponentType
}


const MainContent: React.FC<any> = function (props) {
    let router = useRouter();
    let pathname = router.location.pathname;
    let [root, sub] = pathname.substr(1).split('/');
    let [showFooter, setShowFooter] = useState(true);

    useEffect(() => {
        if (['tracks', 'modules', 'labs', 'lab'].indexOf(root) > -1 && sub) {
            setShowFooter(false);
        }
    }, [root, router, sub]);
    const subtitlelink = '/' + process.env.REACT_APP_BASENAME + '/'

    useEffect(() => {
        toTop('auto')
    }, [pathname])

    useEffect(() => {
        setCanonicalLink()
    }, [window.location.href]);
    
    return (
        <div className='learning_wrap'>
            {
                showFooter ? <dwc-header thin /> : <dwc-mini-header subtitle-text="Learning Labs Center" subtitle-href={subtitlelink} theme="dark" thin container="full" />
            }
            <ThemeProvider theme={theme}>

                <div className='columnFlex'><Route path="/" component={props.comp} key="index" /></div>

            </ThemeProvider>
            {showFooter ? <dwc-footer thin /> : null}
            <ScrollToTop />
            <LearningCenterNPS></LearningCenterNPS>
            <ReportBug />
        </div>
    )
}
const Main: React.FC<MainProps> = (props) => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ConnectedRouter history={history}>
                    <MainContent {...props} />
                </ConnectedRouter>
            </QueryClientProvider>
        </Provider>
    )
}

export default Main;