import React,{ReactElement} from "react"
import {MobileBar} from "../../Tags/categories"
import {ContainerTypes as CategoriesTypes} from "../../Tags/categories/interface"

import "./index.scss"

type SearchFilterBarProps = {
    total: number,
    searchKey: string,
    selectedTags?: string[],
    selectedProducts:string[],
    showTotal?: boolean,
    isLoading?: boolean,
    onFiltersBtnClicked: () => void,
    isFilterPanelShow: boolean,
    listLength: number,
    contentType:string,
    category?:string,
    title:ReactElement,
    isSearchPage:boolean,
    onCategoriedChange:CategoriesTypes['onChange']
}


const SearchFilterBar:React.FC<SearchFilterBarProps>=(props)=>{
    let { selectedProducts,isSearchPage,title,selectedTags = [], onFiltersBtnClicked, isFilterPanelShow = true,onCategoriedChange} = props;
  
    return (
      <div className={`search-filter-bar filters search filter-info-${(!title) ? 'empty' : 'filled'}`}>
        <MobileBar selectedProducts={selectedProducts} isSearchPage={isSearchPage} openPanel={onFiltersBtnClicked} onCategoriedChange={onCategoriedChange} category={selectedTags[0]}></MobileBar>
        <div className="search-filter-bar_content">{props.children ?props.children: <div className="search-filter-bar_title">{title}</div>}</div>
      </div>
    );
  };

export default SearchFilterBar


