import React, { useCallback, useEffect, useState } from 'react';
// import { useEffect } from 'react-router/node_modules/@types/react';
import { request, api } from '../../../utils/request';
import useSWR from 'swr';
import { Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './tag.scss';
import { uniqueArr } from '../../../typings/shared';
export const TechCategories: Category[] = [
    {
        "value": "IoT",
        "label": "IoT",
    },
    {
        "value": "Cloud",
        "label": "Cloud"
    },
    {
        "value": "Networking",
        "label": "Networking"
    },
    {
        "value": "Data Center",
        "label": "Data Center"
    },
    {
        "value": "Collaboration",
        "label": "Collaboration"
    },
    {
        "value": "Security",
        "label": "Security"
    }
    // {
    //     "value": "Analytics & Automation",
    //     "label": "Analytics & Automation"
    // },
    // {
    //     "value": "Mobility",
    //     "label": "Mobility"
    // }
]
export type Category = {
    value: string,
    label?: string,
    type?: string,
    category?: string,
    checked?: boolean,
    subitems?: Category[],
    id?: string,
    count?:number,
    selected?:boolean
}
const convertAllCategories = function (allItems: Category[]) {
    const allCategories = TechCategories.map(cate => {
        const subcategories = allItems.filter(item => {
            let matched = item.type === 'Subcategories' && item.category === cate.value;
            if (matched) {
                //@ts-ignore
                item.label = item.value = item.name;
            }
            return matched;
        })

        const category = { ...cate };

        category.subitems = uniqueArr(subcategories, 'name');

        return category;
    });

    return allCategories;
}

export const Tag: React.FC<{ data: Category, selectedTags: Category[], onTagClicked: (tag: Category) => void }> = function (props) {
    let { data, onTagClicked, selectedTags } = props;
    const [isExpanded, setIsExpanded] = useState<undefined | boolean>(undefined);
    let { label, subitems } = data;


    const isTopLevel = subitems && subitems.length;
    const selected = selectedTags.indexOf(data) > -1;

    let expanded = isExpanded || (isExpanded === undefined && selected) ||
        (isExpanded === undefined && isTopLevel && (data.subitems || []).some(tag => selectedTags.indexOf(tag) > -1));


    const _onTagClicked = useCallback((e, tag) => {
        e.stopPropagation && e.stopPropagation();
        onTagClicked && onTagClicked(tag);
    }, [onTagClicked]);

    const onToggleExpand = useCallback((e) => {
        e.stopPropagation();
        setIsExpanded(!expanded);
    }, [expanded]);

    return (
        <div className="tag-item" id={data.value}>
            <div className="tag-content">
                <Checkbox
                    checked={data.selected}
                    onClick={e => _onTagClicked(e, data)}
                    // onChange={e => console.log(e.target.checked)}
                    color="primary"
                    checkedIcon={<span className='icon checkedIcon' />}
                    icon={<span className='icon' />}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <div className="tag-labelWrap" onClick={onToggleExpand}>
                    <span className='tag-label' style={{cursor:isTopLevel?'pointer':'initial'}} title={label}>{label}</span>
                    {isTopLevel ? (
                        expanded ?
                            <ExpandLessIcon className='cursor-pointer' onClick={onToggleExpand} /> :
                            <ExpandMoreIcon className='cursor-pointer' onClick={onToggleExpand} />)
                        : null
                    }
                    <span className='tag-count'>({data.count||0})</span>
                </div>
            </div>
            {subitems && subitems.length ?
                <div className={`tag-sub-items expand-${expanded}`} style={{ height: `${subitems.length * 34}px` }}>
                    {
                        subitems.map(cat => {
                            return <Tag key={cat.id} data={cat} selectedTags={selectedTags} onTagClicked={e => _onTagClicked(e, cat)} />
                        })
                    }
                </div> : null}

        </div>
    )
}
export type TagsProps = {
    selectedTagStrArr?: string[],
    onTagClicked?: (tag: Category, selectedTags: Category[]) => void
}

const getInitSelectedTags = (selectedTagStrArr: string[], allCategories: Category[]) => {
    let selectedTags: Category[] = [];
    const collectTags = (tags: Category[]) => tags.forEach(tag => {
        if (selectedTagStrArr.indexOf(tag.value) > -1) {
            selectedTags.push(tag);
        }
        if (tag.subitems) {
            collectTags(tag.subitems);
        }
    });

    collectTags(allCategories);
    return selectedTags;
}
export const TechTags: React.FC<TagsProps> = function (props) {
    let { onTagClicked, selectedTagStrArr = [] } = props;
    const [mounted, setMounted] = useState(false);
    const [allCategories, setAllCategories] = useState<Category[]>([]);
    const [selfSelectedTags, setSelectedTags] = useState<Category[]>(getInitSelectedTags(selectedTagStrArr, []));
    const [hardCodeRemoteTags]=useState({data:{items:[]}})

    const data=hardCodeRemoteTags
    //const { data } = useSWR(mounted ? api.tags : null, request.get, { revalidateOnFocus: false });
    
    useEffect(() => {
        //@ts-ignore
        let allItems = data ? data.data.items : [];
        if (allItems) {
            const allCategories = convertAllCategories(allItems as Category[]);
            setAllCategories(allCategories);

            setSelectedTags(getInitSelectedTags(selectedTagStrArr, allCategories));
        }
    }, [data, selectedTagStrArr]);

    useEffect(() => {
        setMounted(true)
    }, []);

    const _onTagClicked = useCallback((tag) => {
        let idx = selfSelectedTags.indexOf(tag);
        // console.log('tag clicked', tag, idx);
        if (idx > -1) {
            selfSelectedTags.splice(idx, 1);

            //TODO:: this is for tag name being same special case, should be eliminated in the furture
            let _idx = selfSelectedTags.findIndex(_tag => _tag.value === tag.value);
            while (_idx > -1) {
                selfSelectedTags.splice(_idx, 1);
                _idx = selfSelectedTags.findIndex(_tag => _tag.value === tag.value);
            }
        } else {
            selfSelectedTags.push(tag);
        }
        setSelectedTags([...selfSelectedTags]);

        if (onTagClicked) {
            onTagClicked(tag, selfSelectedTags);
        }
    }, [onTagClicked, selfSelectedTags]);

    return (
        <div className="tech-tags-root">
            {
                allCategories.map(cat => {
                    return <Tag key={cat.value} data={cat} selectedTags={selfSelectedTags} onTagClicked={_onTagClicked} />
                })
            }
        </div>
    )
}
export default TechTags;
