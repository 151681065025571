import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './error.scss'
type Props = {

}
const ErrorPage: React.FC<Props> = () => {
    useEffect(() => {
        function createMeta(name: string, content: string) {
            let m = document.createElement('meta');
            m.setAttribute('name', name);
            m.setAttribute('content', content);
            document.head && document.head.appendChild(m);
        }

        createMeta('render:status_code', '404');
        createMeta('robots', 'noindex,nofollow');
    }, []);
    return (
        <div id="dn-404-page" className="container">
            <Link to="/" > Go to Homepage </Link>
        </div>)
}


export default ErrorPage;