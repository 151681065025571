import {FOR_YOU_URL} from "../const"
import { useEffect} from 'react';
export function isLoginedIn(){
    if(!window.PlatformSDK||!window.PlatformSDK.isLoggedIn()) return false
    return true
}

export function canGoToForYou(){
    if(!isLoginedIn()){
      window.PlatformSDK.setLoginReturnUrl(FOR_YOU_URL)
      window.PlatformSDK.openLogin("Please login.");
      return false
    }
    return true
}

export function setCanonicalLink(){
  const currentUrl = window.location.href
  // console.log('currentUrl', window.location.href)

  let existingCanonicalLink = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
  if (existingCanonicalLink) {
      existingCanonicalLink.href = currentUrl;
  } else {
      existingCanonicalLink = document.createElement('link');
      existingCanonicalLink.rel = 'canonical';
      existingCanonicalLink.href = currentUrl;
      document.head.appendChild(existingCanonicalLink);
  }
}

export const TitleAndDescribtion = {
  learning: {
      htmlTitle: ' Learning Labs Center - Cisco DevNet',
      metaDescription: 'Learning Labs Tutorials in articles, videos, even with preset development environment with integrated sandboxs to play with. Make it easier to learn by doing.'
  },
  mylearning: {
      htmlTitle: 'My Learning - Learning Labs Center - Cisco DevNet',
      metaDescription: 'Explore your learning history to track your achievements in Learning Labs Center'
  },
  search: {
      htmlTitle: 'Search Learning Content - Learning Labs Center - Cisco DevNet',
      metaDescription: 'Search topics you are interested in to learn more in Learning Labs Center'
  },
  track: {
      htmlTitle: 'Search Learning Tracks - Learning Labs Center - Cisco DevNet',
      metaDescription: 'Search tracks you are interested in to learn more in Learning Labs Center'
  },
  module: {
      htmlTitle: 'Search Learning Modules - Learning Labs Center - Cisco DevNet',
      metaDescription: 'Search modules you are interested in to learn more in Learning Labs Center'
  },
  lab: {
      htmlTitle: 'Search Learning Labs - Learning Labs Center - Cisco DevNet',
      metaDescription: 'Search labs you are interested in to learn more in Learning Labs Center'
  }
}

export const usePageMetadata = (pageTitle: string, pageDescription: string) => {
  
  useEffect(() => {
      document.title = pageTitle;
      const existingMetaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
      if (!existingMetaDescription) {
          const metaDescription = document.createElement('meta');
          metaDescription.name = 'description';
          metaDescription.content = pageDescription;
          document.head.appendChild(metaDescription);
      } else {
          existingMetaDescription.content = pageDescription;
      }
      // console.log('pageDescription:', pageDescription)
      return () => {
          document.title = 'Learning Labs Center - Cisco DevNet'; // 恢复默认标题
      };
  }, [pageTitle, pageDescription]);
};
