import React, { ReactElement, useCallback } from "react";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./NewMenu.scss";
import { useWindowWidth } from "../../utils/windowSize";
import { useReportBug } from "../../pages/ReportBug/ReportBugDialog";

const changeSize = 1000

const expandResources = [
  { title: 'Learning Tracks', link: '/search/tracks/' },
  { title: 'Learning Modules', link: '/search/modules/' },
  { title: 'Learning Labs', link: '/search/labs/' },
]

function createLinks(list: Array<{ title: string, className?: string, link: string }>, className?: string) {
  return list.map(item => <Link key={item.title} to={item.link}>
    <div className={item.className || className}>{item.title}</div>
  </Link>)
}


const MenuItem = (props: { menus?: Array<ReactElement> }) => {
  const { menus } = props;
  let { open: openReportBug } = useReportBug();
  const onReportBugClick = useCallback((e) => {
    openReportBug();
    e.preventDefault();
  }, [openReportBug]);
  return <React.Fragment>
    <a
      href="/startnow/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="item">Start Now</div>
    </a>
    {menus}
    {/* <a
      href="https://developer.cisco.com/certification/fundamentals/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="item">DevNet Associate Fundamentals</div>
    </a> */}
    {/* <a
      href="/learning/"
      rel="noopener noreferrer"
    >
      <div className="item">Back To Previous Version</div>
    </a> */}
    <a
      href={process.env.REACT_APP_FEEDBACK_URL}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onReportBugClick}
    >
      <div className="item">Talk to us</div>
    </a>
  </React.Fragment>
};

const NewMenu: React.FC<{
  width?: string;
  titleSize?: string;
  label: string;
  hiddenLibrary?:boolean,
  inHomepage?:boolean
}> = (props) => {
  const { label, width, titleSize,hiddenLibrary,inHomepage=true } = props;
  const windowWidth = useWindowWidth();

  // const [menu, setMenu] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openItem, setOpenItem] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = () => {
    setOpen(true);
  };
  const changeMenuOpen = (open: boolean) => {
    setOpen(!open);
  };
  const switchOpenItem = (openItem: boolean) => {
    setOpenItem(!openItem);
  };

  return (
    <div className="topMenuRoot">
      <div className={`topMenuWrap ${width === "small" ? "smallWidth" : ""}`}>
        <div className={`title`}>
          {
            label.length === 0 ?inHomepage? <div>Learning Labs Center</div>:<Link to={"/"} className="titleLink">Learning Labs Center</Link> : <Link to={"/"} className="titleLink">
              {label && windowWidth < changeSize ? (
                <div className="homeIcon"></div>
              ) : (
                "Learning Labs Center"
              )}
            </Link>
          }

          <span className="Secondpath">
            {""}
            {label && label.length > 0 ? " > " : ""} {label}
          </span>
        </div>
        {!hiddenLibrary&&<div className="flex topMenuRight">
            <Link to={"/search/?contentType=track,module,lab"}>
              <div className="menu hoverUnderLine">Explore library</div>
            </Link>
        </div>}
      </div>
    </div>
  );
};



export default NewMenu;
