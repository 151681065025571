export const LAB='lab'
export const MODULE='module'
export const TRACK='track'
export const PROGRESS_IN_PROGRESS="InProgress"
export const PROGRESS_COMPLETED="Completed"
export const SORT_DESC='last_modified:desc'
export const FOR_YOU_URL=window.location.origin+'/learning/'
export const projectsQueryCommonParamsFilters=[
    {"field":"status","value":"published"}
]

export const recommendedKeywords=["Network Automation", "AppDynamics", "Full-Stack Observability", "NSO", "Meraki"]