import React, { useEffect, useCallback } from "react"
import useNps from "./hooks/usePlatformNps"
import useTrigerNps from "./hooks/useTriggerNps"
import {isLoginedIn} from "./utils/index"
export {TRIGGER_EVENT_NAME} from "./const"
export {setFormMetadata} from "./hooks/usePlatformNps"


export default function NPS(props:{getFormMetadata?:()=>any,env:string,id:string}) {
    const {getFormMetadata,env,id}=props;
    const [triggerCount,npsData] = useTrigerNps();
    const { loadFormError,openNpsNotification:_openNpsNotification } = useNps(id,env,npsData,getFormMetadata)



    const openNpsNotification=useCallback(()=>{
        if(!isLoginedIn()||loadFormError) return false
        _openNpsNotification()
    },[loadFormError,_openNpsNotification]) 
   

    useEffect(() => {
        if (triggerCount > 0) {
            openNpsNotification()
        }

    }, [triggerCount,openNpsNotification])


    return <></>
}