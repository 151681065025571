import React from "react";
import ReactLoading from 'react-loading';
import { BlankTip } from "../../components";
import Card from "../../components/Card";
import { QueryResult, ContentSubTypes} from "./hooks";
import useGetProgressByCodeNameList from "../../requestHooks/useGetProgressByCodeNameList";

const ContentSubTypeValues = Object.values(ContentSubTypes);
export type ResultListProps = {
    isLoading: boolean,
    contentType: typeof ContentSubTypeValues[number],
    dataList: QueryResult['dataList']
}

const List: React.FC<{
    contentType: ResultListProps['contentType'],
    dataList: ResultListProps['dataList']
}> = function (props) {
    let { dataList, contentType } = props;
    // const progressMap = useAllProgress(dataList, contentType);
    const progressMap = useGetProgressByCodeNameList(dataList);
    
    return (
        <React.Fragment>
            <div className={`${contentType.replace(/,/g, '-')} rightLineWrap`}>
                {
                    //@ts-ignore
                    dataList.map((data: any) => {
                        return (
                            <div key={data.id} className='marginBottom10 result_item'>
                                <Card data={data} progress={(progressMap[data.contentSubtype]||{})[data.code_name]}></Card>
                            </div>
                        )
                    })
                }
                <div className="block"></div><div className="block"></div><div className="block"></div>
            </div>
        </React.Fragment>)

}

const ResultList: React.FC<ResultListProps> = function (props) {
    let { isLoading, dataList, contentType } = props;
   
    return (<div className='search-results'>
        {
            isLoading ? <ReactLoading className="loading" width={150} type='bubbles' color='#049fd9' /> :
                dataList.length ? <List contentType={contentType} dataList={dataList} /> : <BlankTip />
        }
    </div>)
}
export default ResultList;