export const SEARCH_KEYS_MAP=new Map([
    ['categories','categories'],
    // ['categories','tags'],
    ['products','products'],
    ['language','language'],
    ['tags','categories']
])

const TRACK='track';
const MODULE='module';
const LAB='lab'

export type CONTENT_TYPES=['track','module','lab']

export enum SORT_BY_NAMES{
    last_modified='last_modified',
    luceneScore='luceneScore'
}
export const SORT_BY={
    [SORT_BY_NAMES.last_modified]:{
        label:'Newest First',
        asc: false
    },
    [SORT_BY_NAMES.luceneScore]:{
        label:'Most Relevant',
        asc: false
    }
}

export enum LANGUAGE_TAGS{
    English='English',
    Japanese='Japanese'
}

export const SORT_BY_DEFAULT_KEY=SORT_BY_NAMES.last_modified

export const LANGUAGE_MAP={
    'All languages':['English','Japanese'],
    [LANGUAGE_TAGS.English]:'English',
    [LANGUAGE_TAGS.Japanese]:'Japanese'
}

export const ERROR_PAGE_MAP=[
    [[`${TRACK}s`],`/search/${TRACK}s`],
    [[`${MODULE}s`],`/search/${MODULE}s`],
    [[`${LAB}s`],`/search/${LAB}s`],
]

export const TAB_FOR_YOU='forYou';
export const TAB_LIBRARY='library';

export const tabList=[
  {key:TAB_FOR_YOU,name:'For you'},
  {key:TAB_LIBRARY,name:'Explore library'}
]


