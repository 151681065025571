import React, { useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { useDevLearningWidget, useLearningWidgetStyles } from '../Search/hooks';

const Tracks = () => {
  const classes = useLearningWidgetStyles();
  let { trackCodeName } = useParams();

  let pathname = useLocation().pathname
  // console.log("/tracks pathArr,url:", pathname)
  // console.log("/tracks pathArr,url:", pathArr)

  let [selectedKey] = useState(`/${pathname.split('/').slice(3).join('/')}/`.replace(/\/{1,}/g, '/'))
  const extraProps = {
    ratingformid: process.env.REACT_APP_RATING_FORM_ID
  }
  if (selectedKey) {
    extraProps.selecteditemkey = selectedKey;
  }

  useDevLearningWidget('dwc-course-ui', '/tracks/' + trackCodeName);

  return (

    <div className={classes.root}>


      <dwc-course-ui class={classes.dui} courseid={trackCodeName} community="true" status={process.env.REACT_APP_CONTENT_STATUS} {...extraProps}>
        <style>{`@import url('${process.env.REACT_APP_ASSET_URL_BASE}/form/styles/sdk.css')`}</style>
      </dwc-course-ui>

    </div>

  );
};

export default Tracks;
