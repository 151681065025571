import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Root from './Router';
import Main from './Main'
import './css/tailwind.css'
import './index.scss';
const render = (Component: React.FC) => {
    
    ReactDOM.render(
        <Main comp={Component} />,
        document.getElementById('root')
    );
};

render(Root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
